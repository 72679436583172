import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { informationCircle } from 'ionicons/icons';
import React from 'react';
import MxCurrency from '../Shared/services/Format';

interface Props {
  segments: any[];
}

export default function TalentSegments(props: Props) {
  const [showTalentTooltip, setShowTalentTooltip] = React.useState(false);
  return (
    <>
      <IonRow>
        <IonCol size='6' style={{ marginTop: 40 }}>
          <h5>
            Talent Segments{' '}
            <IonIcon
              icon={informationCircle}
              onClick={() => {
                setShowTalentTooltip(!showTalentTooltip);
              }}
            />
          </h5>
          {showTalentTooltip && (
            <div
              style={{
                fontSize: 14,
                color: '#2c2c2c',
                background: '#eee',
                padding: 10,
                borderRadius: 10,
              }}
            >
              &nbsp; &nbsp; These are your current talent segments and determine how much an
              individuals receives from monthly top-ups. If you would like to modify the amount of
              an existing talent segment, please email us at support@maxwell.app.
            </div>
          )}
        </IonCol>
        <IonCol size='6' style={{ textAlign: 'right' }}>
          {/* TODO: add talent segments */}
          {/* <IonButton size="small" color={"dark"} style={{ marginTop: 36 }}>
            Add Talent Segment
          </IonButton> */}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <div
            style={{
              marginTop: 10,
              border: '1px solid black',
              borderRadius: 10,
              padding: 20,
            }}
          >
            <IonRow style={{ fontSize: 12 }}>
              <IonCol size='4'>TITLE</IonCol>
              <IonCol size='6'>PER MONTH</IonCol>
              <IonCol size='2' style={{ textAlign: 'right' }}>
                USERS
              </IonCol>
            </IonRow>
            {props.segments.map((item: any) => (
              <IonRow key={item.name}>
                <IonCol size='4'>{item.name}</IonCol>
                <IonCol size='5'>{MxCurrency(item.budget)}</IonCol>
                <IonCol size='3' style={{ textAlign: 'right' }}>
                  {item.memberCount}
                </IonCol>
              </IonRow>
            ))}
          </div>
        </IonCol>
      </IonRow>
    </>
  );
}
