import { IonButton, IonModal, IonSpinner, IonToggle } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import teamService from '../Shared/services/TeamService';
import MxCurrency from '../Shared/services/Format';
import moment from 'moment';
import WalletService from '../Shared/services/WalletService';

interface Tag {
  id: number;
  title: string;
}

interface Props {
  open: string;
  onClose: () => void;
  onReload: () => void;
  memberIds: number[];
  employer: any;
  controlGroups: any;
  team: any;
  budgets: any;
  regalosTags: Tag[];
  member: any;
}

export default function BulkModals(props: Props) {
  const [controlGroup, setControlGroup] = useState<any>(null);
  const [controlGroupOptions, setControlGroupOptions] = useState<any>(null);
  const [manager, setManager] = useState<any>(null);
  const [teamOptions, setTeamOptions] = useState<any>(null);
  const [teamSearchTimeout, setTeamSearchTimeout] = useState<any>(null);
  const [teamMembersLoading, setTeamMembersLoading] = useState<boolean>(false);
  const [department, setDepartment] = useState<any>(null);
  const [jobTitle, setJobTitle] = useState<any>(null);
  const [workLocation, setWorkLocation] = useState<any>(null);
  const [generalLedger, setGeneralLedger] = useState<any>(null);
  const [spotBonusAmount, setSpotBonusAmount] = useState<number>(0);
  const [spotBonusTag, setSpotBonusTag] = useState<any>(null);
  const [spotBonusMessage, setSpotBonusMessage] = useState<any>(null);
  const [spotBonusBudget, setSpotBonusBudget] = useState<any>(null);
  const [spotBonusPublic, setSpotBonusPublic] = useState<any>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [, setEmail] = useState<any>(null);
  const [altEmail, setAltEmail] = useState<any>(null);
  const [firstName, setFirstName] = useState<any>(null);
  const [lastName, setLastName] = useState<any>(null);
  const [birthday, setBirthday] = useState<any>(null);
  const [anniversary, setAnniversary] = useState<any>(null);
  const [EeID, setEeID] = useState<any>(null);
  const [confirm, setConfirm] = useState<any>(null);

  useEffect(() => {
    if (props.controlGroups) {
      const options: any = [];
      for (let i = 0; i < props.controlGroups.length; i++) {
        options.push({
          value: props.controlGroups[i].id,
          label: props.controlGroups[i].name,
        });
      }

      setControlGroupOptions(options);
    }

    if (props.team) {
      const options: any = [];
      for (let i = 0; i < props.team.length; i++) {
        options.push({
          value: props.team[i].id,
          label: props.team[i].name,
        });
      }
      setTeamOptions(options);
    }
  }, [props.controlGroups, props.team, props.member]);

  function close() {
    props.onClose();
    setControlGroup(null);
    setManager(null);
    setDepartment(null);
    setJobTitle(null);
    setWorkLocation(null);
    setGeneralLedger(null);
    setSpotBonusAmount(0);
    setSpotBonusTag(null);
    setSpotBonusMessage(null);
    setSpotBonusBudget(null);
    setShowSuccess(false);
    setEmail(null);
    setAltEmail(null);
    setFirstName(null);
    setLastName(null);
    setBirthday(null);
    setAnniversary(null);
    setEeID(null);
  }

  function submit() {
    props.onReload();
    setTimeout(() => {
      close();
    }, 500);
  }

  return (
    <>
      <IonModal
        isOpen={props.open == 'removeModal'}
        onDidDismiss={() => close()}
        className='mx-modal-small'
      >
        <div style={{ textAlign: 'center', paddingTop: 10 }}>
          <h1>Remove Members</h1>
          <div style={{ paddingTop: 20, fontSize: 20 }}>
            Are you sure you want to remove{' '}
            <span style={{ fontWeight: 'bold' }}>{props.memberIds?.length}</span> members from{' '}
            {props.employer?.name}?
          </div>
          <div>
            This action cannot be undone. This action will exit and/or remove members from your
            account. Type &quot;confirm&quot; to continue.
          </div>
          <div style={{ paddingTop: 22, flex: 1 }}>
            &nbsp;&nbsp; Confirm
            <input
              className='mx-border'
              style={{ marginTop: 0 }}
              placeholder="Type 'confirm' to continue..."
              onChange={(e) => {
                setConfirm(e.target.value);
              }}
            />
          </div>
          <div style={{ paddingTop: 30 }}>
            <IonButton
              color={'dark'}
              disabled={confirm != 'confirm'}
              onClick={() => {
                for (const id of props.memberIds) {
                  teamService.memberExit(id).then((res: any) => {
                    console.log(res);
                    submit();
                  });
                }
              }}
            >
              Yes, Remove Members
            </IonButton>
          </div>
          <div style={{ paddingTop: 20 }}>
            <IonButton color={'light'} onClick={() => close()}>
              No, Cancel
            </IonButton>
          </div>
        </div>
      </IonModal>

      {/* Edit Modal */}
      <IonModal
        isOpen={props.open == 'editModal'}
        onDidDismiss={() => close()}
        className='mx-modal-small'
      >
        {props.open == 'editModal' && (
          <div style={{ textAlign: 'center', paddingTop: 10 }}>
            <h1>Edit Members</h1>
            <div style={{ paddingTop: 20, fontSize: 20 }}>
              Edit <span style={{ fontWeight: 'bold' }}>{props.memberIds?.length}</span> members
              from {props.employer?.name}?
            </div>
            <div style={{ textAlign: 'left', display: 'flex' }}>
              {' '}
              {props.memberIds && props.memberIds.length == 1 && (
                <div style={{ width: 400 }}>
                  {/* change first name */}
                  <div style={{ paddingTop: 22, flex: 1 }}>
                    &nbsp;&nbsp; First Name
                    <input
                      className='mx-border'
                      style={{ marginTop: 0 }}
                      placeholder={
                        props.member ? props.member?.firstName : 'Update their First Name...'
                      }
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </div>
                  {/* change last name */}
                  <div style={{ paddingTop: 22, flex: 1 }}>
                    &nbsp;&nbsp; Last Name
                    <input
                      className='mx-border'
                      style={{ marginTop: 0 }}
                      placeholder={
                        props.member ? props.member?.lastName : 'Update their Last Name...'
                      }
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </div>
                  {/* Change EEID */}
                  <div style={{ paddingTop: 22, flex: 1 }}>
                    &nbsp;&nbsp; Employee ID
                    <input
                      className='mx-border'
                      style={{ marginTop: 0 }}
                      placeholder={props.member ? props.member?.eeId : 'Add an EEID...'}
                      value={EeID}
                      onChange={(e) => {
                        setEeID(e.target.value);
                      }}
                    />
                  </div>
                  {/* change email */}
                  <div style={{ paddingTop: 22, flex: 1 }}>
                    &nbsp;&nbsp; Alternate Email
                    <input
                      className='mx-border'
                      style={{ marginTop: 0 }}
                      placeholder={props.member?.altEmailAddress}
                      value={altEmail}
                      onChange={(e) => {
                        setAltEmail(e.target.value);
                      }}
                    />
                  </div>
                  {/* change birthday */}
                  <div style={{ paddingTop: 22, flex: 1 }}>
                    &nbsp;&nbsp; Birthday
                    <input
                      type='date'
                      className='mx-border'
                      style={{ marginTop: 0 }}
                      placeholder='Add their Birthday...'
                      value={birthday}
                      defaultValue={
                        props.member &&
                        props.member.birthday &&
                        moment(props.member?.birthday).format('YYYY-MM-DD')
                      }
                      onChange={(e) => {
                        setBirthday(e.target.value);
                      }}
                    />
                  </div>
                  {/* change anniversary */}
                  <div style={{ paddingTop: 22, flex: 1 }}>
                    &nbsp;&nbsp; Anniversary
                    <input
                      type='date'
                      className='mx-border'
                      style={{ marginTop: 0 }}
                      placeholder='Add their Work Anniversary...'
                      value={anniversary}
                      defaultValue={
                        props.member &&
                        props.member.anniversaryDate &&
                        moment(props.member?.anniversaryDate).format('YYYY-MM-DD')
                      }
                      onChange={(e) => {
                        setAnniversary(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
              <div style={{ width: 400 }}>
                {/* change control group */}
                {controlGroupOptions && (
                  <div style={{ paddingTop: 22 }}>
                    <>
                      &nbsp;&nbsp; Talent Segment
                      <div
                        style={{
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingBottom: 8,
                        }}
                      >
                        <Select
                          required={true}
                          placeholder={
                            props.member && props.member.controlGroupName
                              ? props.member.controlGroupName
                              : ' Select...'
                          }
                          value={controlGroup}
                          options={controlGroupOptions}
                          onChange={(e: any) => {
                            console.log(e);
                            setControlGroup(e);
                          }}
                        />
                      </div>
                    </>
                  </div>
                )}
                {/* change Manager */}
                <div style={{ paddingTop: 22 }}>
                  <div style={{ display: 'flex', height: 24 }}>
                    <div>&nbsp;&nbsp; Manager</div>
                    <div style={{ paddingLeft: 8, paddingTop: 0 }}>
                      {teamMembersLoading && <IonSpinner name='dots' />}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingBottom: 8,
                    }}
                  >
                    <Select
                      required={false}
                      placeholder={
                        props.member && props.member.currentManagerId
                          ? props.member.currentManager
                          : ' Select...'
                      }
                      options={teamOptions}
                      value={manager}
                      onInputChange={(e: any) => {
                        if (e.length === 0) {
                          return;
                        }
                        setTeamMembersLoading(true);
                        clearTimeout(teamSearchTimeout);
                        setTeamSearchTimeout(
                          setTimeout(() => {
                            teamService
                              .allTeamMembers({
                                search: e,
                              })
                              .then((res: any) => {
                                console.log(res.data);
                                const options: any = [];
                                for (const m of res.data.members) {
                                  options.push({
                                    value: m.id,
                                    label: m.name,
                                  });
                                }
                                setTeamOptions(options);
                              })
                              .finally(() => setTeamMembersLoading(false));
                            console.log(e);
                          }, 1000),
                        );

                        console.log(e);
                      }}
                      onChange={(e: any) => {
                        console.log('e', e);
                        setManager(e);
                      }}
                    />
                  </div>
                </div>
                {/* change Department */}
                <div style={{ paddingTop: 22, flex: 1 }}>
                  &nbsp;&nbsp; Department
                  <input
                    className='mx-border'
                    style={{ marginTop: 0 }}
                    placeholder={
                      props.member
                        ? props.member?.department
                        : 'Set the Department they belong to...'
                    }
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                    }}
                  />
                </div>
                {/* change Job Title */}
                <div style={{ paddingTop: 22, flex: 1 }}>
                  &nbsp;&nbsp; Job Title
                  <input
                    className='mx-border'
                    style={{ marginTop: 0 }}
                    placeholder={
                      props.member && props.member.jobTitle
                        ? props.member.jobTitle
                        : 'Set a Job Title...'
                    }
                    value={jobTitle}
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                    }}
                  />
                </div>
                {/* change Work Location */}
                <div style={{ paddingTop: 22, flex: 1 }}>
                  &nbsp;&nbsp; Work Location
                  <input
                    className='mx-border'
                    style={{ marginTop: 0 }}
                    placeholder={
                      props.member ? props.member?.workLocation : 'Set the name of Workplace...'
                    }
                    value={workLocation}
                    onChange={(e) => {
                      setWorkLocation(e.target.value);
                    }}
                  />
                </div>
                {/* change General Ledger */}
                <div style={{ paddingTop: 22, flex: 1 }}>
                  &nbsp;&nbsp; General Ledger
                  <input
                    className='mx-border'
                    style={{ marginTop: 0 }}
                    placeholder={
                      props.member
                        ? props.member?.generalLedger
                        : 'Set the General Ledger they belong to...'
                    }
                    value={generalLedger}
                    onChange={(e) => {
                      setGeneralLedger(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ paddingTop: 30 }}>
              <IonButton
                color={'dark'}
                onClick={() => {
                  const body: any = {
                    memberIds: props.memberIds,
                  };
                  if (controlGroup) {
                    body.controlGroupId = controlGroup.value;
                  }
                  if (manager) {
                    body.currentManagerId = manager.value;
                  }
                  if (department) {
                    body.department = department;
                  }
                  if (jobTitle) {
                    body.jobTitle = jobTitle;
                  }
                  if (workLocation) {
                    body.workLocation = workLocation;
                  }
                  if (generalLedger) {
                    body.generalLedger = generalLedger;
                  }
                  if (firstName) {
                    body.firstName = firstName;
                  }
                  if (lastName) {
                    body.lastName = lastName;
                  }
                  if (birthday) {
                    body.birthdate = birthday;
                  }
                  if (anniversary) {
                    body.anniversaryDate = anniversary;
                  }
                  if (EeID) {
                    body.eeId = EeID;
                  }
                  if (altEmail) {
                    body.altEmail = altEmail;
                  }
                  console.log(body);

                  teamService.bulkEditMembers(body).then(() => {
                    submit();
                  });

                  // alert("Removed members!");
                }}
              >
                Update Fields
              </IonButton>
            </div>
            <div style={{ paddingTop: 20 }}>
              <IonButton color={'light'} onClick={() => close()}>
                Cancel
              </IonButton>
            </div>
          </div>
        )}
      </IonModal>

      {/* Spot Modal */}
      <IonModal
        isOpen={props.open == 'spotModal'}
        onDidDismiss={() => close()}
        className='mx-modal-small'
      >
        <div style={{ textAlign: 'center', paddingTop: 10 }}>
          <h1>Spot Members</h1>
          <div style={{ paddingTop: 20, fontSize: 20 }}>
            Send bonus to <span style={{ fontWeight: 'bold' }}>{props.memberIds?.length}</span>{' '}
            members?
          </div>
          <div style={{ width: 400, textAlign: 'left' }}>
            <>
              <div>
                <div style={{ padding: 10 }}>
                  {props.budgets && props.budgets.length > 0 ? (
                    <div style={{ marginTop: 20 }}>
                      <b>Bonus Wallets</b>{' '}
                      <div>
                        <select
                          onChange={(e) => {
                            console.log(e.target.value);
                            setSpotBonusBudget(JSON.parse(e.target.value));
                          }}
                          style={{
                            border: '1px solid black',
                            borderRadius: 8,
                            padding: 6,
                            backgroundColor: '#fcfcfc !important',
                            width: '100%',
                            boxShadow: '2px 2px 4px -2px rgba(0,0,0,0.75)',
                          }}
                        >
                          <option value={0} disabled selected>
                            Select budget to use...
                          </option>
                          {props.budgets
                            .filter((budget: any) => budget.isActive)
                            .map((budget: any) => {
                              return (
                                <option key={budget.id} value={JSON.stringify(budget)}>
                                  {budget.name} - {MxCurrency(budget.balance)}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginTop: 20 }}>
                      <b>No Admin Budgets Setup</b>
                      <br />
                      Contact Maxwell to get this feature setup.
                    </div>
                  )}
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, padding: 10 }}>
                    <b>Bonus Amount*</b>
                    <div>
                      <input
                        onChange={(e) => {
                          setSpotBonusAmount(e.target.valueAsNumber);
                        }}
                        type='number'
                        placeholder='$0.00'
                        style={{
                          border: '1px solid black',
                          borderRadius: 8,
                          padding: 5,
                          backgroundColor: '#fcfcfc !important',
                          width: '100%',
                          boxShadow: '2px 2px 3px -2px rgba(0,0,0,0.75)',
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1, padding: 10 }}>
                    <b>Title*</b>
                    <div>
                      <select
                        onChange={(e) => {
                          setSpotBonusTag(e.target.value);
                          const tag = props.regalosTags.find(
                            (tag: any) => tag.id === parseInt(e.target.value),
                          );
                          console.log('tag', tag);
                        }}
                        placeholder='Select One'
                        style={{
                          border: '1px solid black',
                          borderRadius: 8,
                          padding: 6,
                          backgroundColor: '#fcfcfc !important',
                          width: '100%',
                          boxShadow: '2px 2px 4px -2px rgba(0,0,0,0.75)',
                        }}
                      >
                        <option disabled selected>
                          Select why you&apos;re sending...
                        </option>
                        {props.regalosTags.map((tag: any) => {
                          return (
                            <option key={tag.id} value={tag.id}>
                              {tag.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div style={{ padding: 10 }}>
                  <b>Message*</b>
                  <div>
                    <textarea
                      onChange={(e) => {
                        setSpotBonusMessage(e.target.value);
                      }}
                      style={{
                        border: '1px solid black',
                        borderRadius: 8,
                        padding: 5,
                        backgroundColor: '#fcfcfc',
                        width: '100%',
                        boxShadow: '2px 2px 3px -2px rgba(0,0,0,0.75)',
                      }}
                    />
                  </div>
                </div>
                <div>
                  <IonToggle
                    style={{
                      width: '90%',
                    }}
                    checked={spotBonusPublic}
                    onIonChange={(e) => setSpotBonusPublic(e.detail.checked)}
                  >
                    <b>Public</b>
                  </IonToggle>
                </div>
                <div style={{ padding: 10 }}>
                  <b>Total:</b>{' '}
                  {spotBonusAmount
                    ? MxCurrency(spotBonusAmount * 100 * props.memberIds.length)
                    : '$0.00'}
                </div>
                <div>
                  {!showSuccess ? (
                    <IonButton
                      disabled={
                        !spotBonusAmount ||
                        !spotBonusTag ||
                        !spotBonusMessage ||
                        !spotBonusBudget ||
                        spotBonusBudget === 0 ||
                        spotBonusAmount * 100 * props.memberIds.length > spotBonusBudget.balance
                      }
                      color='dark'
                      expand='block'
                      onClick={async () => {
                        const promises = props.memberIds.map((memberId) => {
                          const tag = props.regalosTags.find(
                            (tag) => tag.id === parseInt(spotBonusTag),
                          );

                          return WalletService.SendToMember({
                            BudgetId: spotBonusBudget.id,
                            MemberId: memberId,
                            Amount: Math.round(spotBonusAmount * 100),
                            Reason: tag?.title ?? 'Budget Transfer',
                            Message: spotBonusMessage,
                            TagId: spotBonusTag,
                            IsPublic: spotBonusPublic,
                          }).catch((error) => {
                            console.error(`Error sending spot bonuses to ${memberId}:`, error);
                          });
                        });

                        await Promise.all(promises);
                        close();
                      }}
                      style={{
                        margin: 10,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      Send
                    </IonButton>
                  ) : (
                    <IonButton
                      disabled={true}
                      color='light'
                      expand='block'
                      onClick={() => {
                        setShowSuccess(false);
                        close();
                      }}
                      style={{
                        margin: 10,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      Success 👍
                    </IonButton>
                  )}
                </div>
              </div>{' '}
            </>
          </div>

          <div style={{ paddingTop: 0 }}>
            <IonButton color={'light'} onClick={() => close()}>
              Cancel
            </IonButton>
          </div>
        </div>
      </IonModal>
    </>
  );
}
