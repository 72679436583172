import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { selectedWalletState } from './atoms';
import { IonButton } from '@ionic/react';
import WalletTabs from './Tabs';
import AccessModal from './AccessModal';
import BudgetModal from './BudgetModal';
import { Member } from '../../Shared/types/member';
import WalletService from '../../Shared/services/WalletService';

function WalletView() {
  const [selectedWallet, setSelectedWallet] = useRecoilState(selectedWalletState);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [access, setAccess] = useState<Member[]>([]);
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editedName, setEditedName] = useState(selectedWallet?.name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setEditedName(selectedWallet?.name);
  }, [selectedWallet]);

  function handleNameChange() {
    if (selectedWallet?.id) {
      setEditName(false);
      WalletService.UpdateWalletDetails(selectedWallet.id, {
        Name: editedName,
      })
        .then((res) => {
          setSelectedWallet(res.data);
        })
        .catch(() => setEditedName(selectedWallet?.name));
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // Blur the input element
      inputRef.current && inputRef.current.blur();
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>Recognition Wallets</h3>
      </div>
      {selectedWallet == null ? (
        <h5>No wallet selected</h5>
      ) : (
        <div
          style={{
            marginTop: 20,
            padding: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {editName ? (
                <input
                  ref={inputRef}
                  className='mx-border'
                  style={{
                    margin: 0,
                  }}
                  type='text'
                  value={editedName}
                  onChange={(e) => {
                    setEditedName(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  onBlur={handleNameChange}
                  autoFocus
                />
              ) : (
                <h5 onClick={() => setEditName(true)}>{editedName}</h5>
              )}
              <p
                style={{
                  color: 'grey',
                  fontSize: 12,
                }}
              >
                {/* Created {selectedWallet.createdDate.toDateString()} */}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
              }}
            >
              <IonButton color='light' size='small' onClick={() => setShowMemberModal(true)}>
                Add Managers
              </IonButton>
              <IonButton color='light' size='small' onClick={() => setShowBudgetModal(true)}>
                Add Budget
              </IonButton>
            </div>
          </div>
          <WalletTabs />
          <AccessModal
            showModal={showMemberModal}
            selectedUsers={access}
            initialUsers={selectedWallet?.managers}
            onDismiss={() => {
              setShowMemberModal(false);
              setAccess([]);
            }}
            onSave={(selected) => {
              selected.forEach((user) => {
                WalletService.AddMember({
                  WalletId: selectedWallet?.id,
                  MemberId: user.id,
                }).then((res) => {
                  setSelectedWallet(res.data);
                });
              });
              setShowMemberModal(false);
              setAccess([]);
            }}
          />
          {showBudgetModal && (
            <BudgetModal showModal={showBudgetModal} onDismiss={() => setShowBudgetModal(false)} />
          )}
        </div>
      )}
    </div>
  );
}

export default WalletView;
