import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonRow,
  IonSpinner,
  IonToggle,
} from '@ionic/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Pencil, XSquare } from 'react-bootstrap-icons';
import WalletService from '../Shared/services/WalletService';
import MxCurrency from '../Shared/services/Format';
import teamService from '../Shared/services/TeamService';

interface Props {
  member: any;
  showModal: boolean;
  dismissed: () => void;
  editMember: () => void;
  controlGroups: any;
  employer: any;
  budgets: any;
  regalosTags: any;
  bulkediting: boolean;
}

export default function MemberModal(props: Props) {
  const [member, setMember] = useState<any>(null);
  const [regalosSent, setRegalosSent] = useState<any[]>([]);
  const [regalosReceived, setRegalosReceived] = useState<any[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showEditTalentSegment, setShowEditTalentSegment] = useState(false);
  const [showSpotBonusMenu, setShowSpotBonusMenu] = useState(false);
  const [spotBonusAmount, setSpotBonusAmount] = useState<any>(0);
  const [spotBonusMessage, setSpotBonusMessage] = useState<any>('');
  const [spotBonusBudget, setSpotBonusBudget] = useState<any>(null);
  const [spotBonusPublic, setSpotBonusPublic] = useState<any>(false);
  const [showSuccess, setShowSuccess] = useState<any>(false);
  const [regalosStats, setRegalosStats] = useState<any>(null);
  const [spotBonusTag, setSpotBonusTag] = useState<any>(null);

  useEffect(() => {
    console.log(props.member);
    teamService.member(props.member.id).then((memres) => {
      console.log('member extended', memres.data);
      setMember(memres.data);

      console.log('tags', props.regalosTags);

      teamService.MemberRegalos(memres.data.id).then((rres) => {
        console.log;
        setRegalosStats(rres.data);
        setRegalosSent(rres.data.regalosSent);
        setRegalosReceived(rres.data.regalosReceived);
      });
    });
  }, [props.member]);

  function closeSpotBonusMenu() {
    setShowSpotBonusMenu(false);
    setSpotBonusAmount(0);
    setSpotBonusTag(null);
    setSpotBonusMessage('');
  }

  function sendSpotBonus() {
    console.log('sendSpotBonus', spotBonusAmount, spotBonusTag, spotBonusMessage, spotBonusBudget);
    const tag = props.regalosTags.find((tag: any) => tag.id === parseInt(spotBonusTag));
    console.log('tag', tag);
    WalletService.SendToMember({
      BudgetId: spotBonusBudget.id,
      MemberId: props.member.id,
      Amount: Math.round(spotBonusAmount * 100),
      Reason: tag.title,
      Message: spotBonusMessage,
      TagId: spotBonusTag,
      IsPublic: spotBonusPublic,
    }).then((res: any) => {
      console.log('sendSpotBonus', res);
      setShowSuccess(true);
      setTimeout(() => {
        closeSpotBonusMenu();
        props.dismissed();
        setShowSuccess(false);
      }, 3000);
    });
  }

  return (
    <IonModal
      isOpen={props.showModal}
      className='mx-modal'
      onDidDismiss={() => {
        props.dismissed();
      }}
    >
      <IonContent>
        <div
          style={{
            fontSize: 44,
            textAlign: 'right',
            paddingTop: 22,
            paddingRight: 22,
          }}
        >
          <XSquare
            size={34}
            onClick={() => {
              props.dismissed();
            }}
          />
          {showSpotBonusMenu && (
            <>
              <div
                onClick={() => {
                  closeSpotBonusMenu();
                }}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '120vw',
                  height: '120vh',
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  backdropFilter: 'blur(2px)',
                  zIndex: 1,
                }}
              ></div>
              <div
                style={{
                  width: 460,
                  minHeight: 100,
                  border: '1px solid black',
                  borderBottom: '2px solid black',
                  borderRight: '2px solid black',
                  position: 'absolute',
                  top: 140,
                  right: 26,
                  zIndex: 1000,
                  backgroundColor: '#fcfcfc',
                  padding: 16,
                  borderRadius: 10,
                  fontSize: 16,
                  textAlign: 'left',
                  boxShadow: '4px 4px 10px -5px rgba(0,0,0,0.75)',
                }}
              >
                <div style={{ padding: 10 }}>
                  Send a Spot Bonus to {member.preferredName ? member.preferredName : member.name}.
                  They will receive their bonus and a notification instantly.
                  {props.budgets && props.budgets.length > 0 ? (
                    <div style={{ marginTop: 20 }}>
                      <b>Budget</b>{' '}
                      <div>
                        <select
                          onChange={(e) => {
                            console.log(e.target.value);
                            setSpotBonusBudget(JSON.parse(e.target.value));
                          }}
                          style={{
                            border: '1px solid black',
                            borderRadius: 8,
                            padding: 6,
                            backgroundColor: '#fcfcfc !important',
                            width: '100%',
                            boxShadow: '2px 2px 4px -2px rgba(0,0,0,0.75)',
                          }}
                        >
                          <option value={0} disabled selected>
                            Select budget to use
                          </option>
                          {props.budgets
                            .filter((budget: any) => budget.isActive)
                            .map((budget: any) => {
                              return (
                                <option key={budget.id} value={JSON.stringify(budget)}>
                                  {budget.name} - {MxCurrency(budget.balance)}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginTop: 20 }}>
                      <b>No Admin Budgets Setup</b>
                      <br />
                      Contact Maxwell to get this feature setup.
                    </div>
                  )}
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, padding: 10 }}>
                    <b>Bonus Amount*</b>
                    <div>
                      <input
                        onChange={(e) => {
                          setSpotBonusAmount(e.target.value);
                        }}
                        type='number'
                        placeholder='$0.00'
                        style={{
                          border: '1px solid black',
                          borderRadius: 8,
                          padding: 5,
                          backgroundColor: '#fcfcfc !important',
                          width: '100%',
                          boxShadow: '2px 2px 3px -2px rgba(0,0,0,0.75)',
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1, padding: 10 }}>
                    <b>Title*</b>
                    <div>
                      <select
                        onChange={(e) => {
                          setSpotBonusTag(e.target.value);
                          const tag = props.regalosTags.find(
                            (tag: any) => tag.id === parseInt(e.target.value),
                          );
                          console.log('tag', tag);
                        }}
                        placeholder='Select One'
                        style={{
                          border: '1px solid black',
                          borderRadius: 8,
                          padding: 6,
                          backgroundColor: '#fcfcfc !important',
                          width: '100%',
                          boxShadow: '2px 2px 4px -2px rgba(0,0,0,0.75)',
                        }}
                      >
                        <option disabled selected>
                          Select One
                        </option>
                        {props.regalosTags.map((tag: any) => {
                          return (
                            <option key={tag.id} value={tag.id}>
                              {tag.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div style={{ padding: 10 }}>
                  <b>Message*</b>
                  <div>
                    <textarea
                      onChange={(e) => {
                        setSpotBonusMessage(e.target.value);
                      }}
                      style={{
                        border: '1px solid black',
                        borderRadius: 8,
                        padding: 5,
                        backgroundColor: '#fcfcfc',
                        width: '100%',
                        boxShadow: '2px 2px 3px -2px rgba(0,0,0,0.75)',
                      }}
                    />
                  </div>
                </div>
                <div>
                  <IonToggle
                    style={{
                      width: '90%',
                    }}
                    checked={spotBonusPublic}
                    onIonChange={(e) => setSpotBonusPublic(e.detail.checked)}
                  >
                    <b>Public</b>
                  </IonToggle>
                </div>
                <div>
                  {!showSuccess ? (
                    <IonButton
                      disabled={
                        !spotBonusAmount ||
                        !spotBonusTag ||
                        !spotBonusMessage ||
                        !spotBonusBudget ||
                        spotBonusBudget === 0 ||
                        spotBonusAmount * 100 > spotBonusBudget.balance
                      }
                      color='light'
                      expand='block'
                      onClick={() => {
                        sendSpotBonus();
                      }}
                      style={{
                        margin: 10,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      Send
                    </IonButton>
                  ) : (
                    <IonButton
                      disabled={true}
                      color='light'
                      expand='block'
                      onClick={() => {
                        setShowSuccess(false);
                      }}
                      style={{
                        margin: 10,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      Success 👍
                    </IonButton>
                  )}
                </div>
              </div>{' '}
            </>
          )}
        </div>
        <div style={{ padding: 22, marginTop: -40 }}>
          {member ? (
            <IonGrid className='ion-no-padding'>
              <IonRow>
                <IonCol size='3' style={{ textAlign: 'center' }}>
                  <div
                    className='mx-border'
                    style={{
                      height: 120,
                      width: 110,
                      backgroundImage: `url(${props.member.picture})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                    }}
                  ></div>
                </IonCol>
                <IonCol size='6' style={{ paddingTop: 18 }}>
                  <b>{member?.name}</b>
                  <br />
                  <div style={{ fontSize: 'small' }}>
                    <b>Preferred Name:</b> {member?.preferredName}
                  </div>

                  <div style={{ fontSize: 'small' }}>
                    <b>Gender:</b> {member?.genderName}
                  </div>
                  <div style={{ fontSize: 'small' }}>
                    <b>Birthday:</b> {moment(member?.birthday).format('M/D/YYYY')}
                  </div>
                  <div>
                    {props.bulkediting == true && (
                      <IonButton
                        onClick={() => {
                          props.editMember();
                        }}
                        color='dark'
                        size='small'
                      >
                        Edit Member
                      </IonButton>
                    )}
                  </div>
                </IonCol>
                <IonCol style={{ paddingTop: 20 }}>
                  {member?.isActive == true ? (
                    <IonButton
                      color='dark'
                      expand='block'
                      onClick={() => {
                        if (showSpotBonusMenu) {
                          setSpotBonusAmount(0);
                          setSpotBonusMessage('');
                          setSpotBonusTag(null);
                        }
                        setShowSpotBonusMenu(!showSpotBonusMenu);
                      }}
                    >
                      Spot Bonus 👏
                    </IonButton>
                  ) : (
                    <IonButton
                      color='dark'
                      expand='block'
                      onClick={() => {
                        teamService.approveMember(props.member.id).then(() => {
                          props.dismissed();
                          // setLoading(false);
                        });
                      }}
                    >
                      Approve
                    </IonButton>
                  )}
                  <IonButton
                    onClick={() => {
                      setShowAlert(true);
                    }}
                    color='light'
                    expand='block'
                  >
                    Remove
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: 34 }}>
                <IonCol size='12' style={{ fontWeight: 500, fontSize: 18, paddingBottom: 12 }}>
                  This Year&apos;s Budget
                </IonCol>
                <IonCol size='3' style={{ fontWeight: 500 }}>
                  Talent Segment{' '}
                  <Pencil
                    onClick={() => {
                      setShowEditTalentSegment(!showEditTalentSegment);
                    }}
                    size={16}
                  />
                </IonCol>
                <IonCol size='3' style={{ fontWeight: 500 }}>
                  Top-Up Amount
                </IonCol>
                <IonCol size='3' style={{ fontWeight: 500 }}>
                  Budget Available
                </IonCol>
                <IonCol size='3' style={{ fontWeight: 500 }}>
                  Moments Created
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='3' style={{ paddingRight: 10 }}>
                  {showEditTalentSegment == false ? (
                    member?.controlGroupName
                  ) : (
                    <>
                      <select
                        defaultValue={member?.controlGroupId}
                        onChange={(e) => {
                          teamService
                            .setControlGroup(props.member.id, e.target.value)
                            .then((res: any) => {
                              console.log(res.data);

                              teamService.member(props.member.id).then((memres) => {
                                console.log('member extended', memres.data);
                                setMember(memres.data);
                                setShowEditTalentSegment(false);
                              });
                            });
                        }}
                        style={{
                          padding: 4,
                          width: '100%',
                          borderRadius: 6,
                          borderBottom: '2px solid black',
                          borderRight: '2px solid black',
                        }}
                      >
                        {props.controlGroups.map((cg: any) => {
                          return (
                            <option key={cg.id} value={cg.id}>
                              {cg.name}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  )}
                </IonCol>
                <IonCol size='3'> {MxCurrency(member?.controlGroupBudget)}</IonCol>
                <IonCol size='3'>{MxCurrency(member?.balance)}</IonCol>
                <IonCol size='3'>{member?.momentsCreated} moments</IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: 34 }}>
                <IonCol size='12' style={{ fontWeight: 500, fontSize: 18, paddingBottom: 12 }}>
                  This Year&apos;s Regalos
                </IonCol>
                <IonCol size='3' style={{ fontWeight: 500 }}>
                  Number Sent
                </IonCol>
                <IonCol size='3' style={{ fontWeight: 500 }}>
                  Amount Sent
                </IonCol>
                <IonCol size='3' style={{ fontWeight: 500 }}>
                  Number Received
                </IonCol>
                <IonCol size='3' style={{ fontWeight: 500 }}>
                  Amount Received
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='3'>{regalosStats?.numberRegalosSent} regalos</IonCol>
                <IonCol size='3'>{MxCurrency(regalosStats?.totalRegalosSent)}</IonCol>
                <IonCol size='3'>{regalosStats?.numberRegalosReceived} regalos</IonCol>
                <IonCol size='3'>{MxCurrency(regalosStats?.totalRegalosReceived)}</IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: 22 }}>
                <IonCol size='12'>
                  <div className='mx-border' style={{ padding: 22 }}>
                    <div style={{ fontWeight: 500 }}>Regalos Sent</div>
                    <IonRow>
                      <IonCol style={{ fontWeight: 500 }} size='4'>
                        Receiver
                      </IonCol>
                      <IonCol style={{ fontWeight: 500 }} size='4'>
                        Reason
                      </IonCol>
                      <IonCol style={{ fontWeight: 500, textAlign: 'right' }} size='4'>
                        Amount
                      </IonCol>
                    </IonRow>
                    {regalosSent &&
                      regalosSent.map((regalo: any) => {
                        return (
                          <div key={regalo.id}>
                            <IonRow style={{ marginTop: 22 }}>
                              <IonCol style={{}} size='4'>
                                {regalo.receiverName}
                              </IonCol>
                              <IonCol style={{}} size='4'>
                                {regalo.reason}
                              </IonCol>
                              <IonCol style={{ textAlign: 'right' }} size='4'>
                                {MxCurrency(regalo.amount)}
                                <div style={{ fontSize: 12, marginTop: -6 }}>
                                  {regalo.date && moment(regalo.date).format('MMM DD, YYYY HH:mm')}
                                </div>
                              </IonCol>
                              <IonCol
                                size='12'
                                style={{
                                  marginTop: 0,
                                  fontSize: 14,
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                }}
                              >
                                {regalo.message}
                              </IonCol>
                            </IonRow>
                            <div className='lines'></div>
                          </div>
                        );
                      })}
                  </div>
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: 22 }}>
                <IonCol size='12'>
                  <div className='mx-border' style={{ padding: 22 }}>
                    <div style={{ fontWeight: 500 }}>Regalos Received</div>
                    <IonRow>
                      <IonCol style={{ fontWeight: 500 }} size='4'>
                        Sender
                      </IonCol>
                      <IonCol style={{ fontWeight: 500 }} size='4'>
                        Reason
                      </IonCol>
                      <IonCol style={{ fontWeight: 500, textAlign: 'right' }} size='4'>
                        Amount
                      </IonCol>
                    </IonRow>
                    {regalosReceived &&
                      regalosReceived.map((regalo: any) => {
                        return (
                          <div key={regalo.id}>
                            <IonRow style={{ marginTop: 22 }}>
                              <IonCol style={{}} size='4'>
                                {regalo.senderName}
                              </IonCol>
                              <IonCol style={{}} size='4'>
                                {regalo.reason}
                              </IonCol>
                              <IonCol style={{ textAlign: 'right' }} size='4'>
                                {MxCurrency(regalo.amount)}
                                <div style={{ fontSize: 12, marginTop: -6 }}>
                                  {regalo.date && moment(regalo.date).format('MMM DD, YYYY HH:mm')}
                                </div>
                              </IonCol>
                              <IonCol
                                size='12'
                                style={{
                                  marginTop: 0,
                                  fontSize: 14,
                                  paddingLeft: 12,
                                  paddingRight: 12,
                                }}
                              >
                                {regalo.message}
                              </IonCol>
                            </IonRow>
                            <div className='lines'></div>
                          </div>
                        );
                      })}
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  offset='8'
                  size='4'
                  style={{
                    paddingTop: 18,
                    textAlign: 'right',
                    paddingRight: 10,
                  }}
                >
                  <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={'Remove Member?'}
                    message={'Are you sure you want to remove this member?'}
                    buttons={[
                      {
                        text: 'Cancel',
                        role: 'cancel',
                      },
                      {
                        text: 'Yes, Remove',
                        role: 'confirm',
                        handler: () => {
                          teamService.memberExit(props.member.id).then(() => {
                            props.dismissed();
                          });
                        },
                      },
                    ]}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <div style={{ textAlign: 'center', paddingTop: 22 }}>
              <IonSpinner name='lines' />
            </div>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
}
