import { IonButton } from '@ionic/react';
import React, { useState } from 'react';
import WalletMembers from './Members';
import WalletActivity from './Activity';

const tabs = [
  {
    name: 'Activity',
    component: <WalletActivity />,
  },
  {
    name: 'Wallet Members',
    component: <WalletMembers />,
  },
];

function WalletTabs() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
        }}
      >
        {tabs.map((tab, index) => (
          <IonButton
            key={tab.name}
            size='small'
            color={activeTab === index ? 'dark' : 'light'}
            onClick={() => setActiveTab(index)}
          >
            {tab.name}
          </IonButton>
        ))}
      </div>
      <div
        style={{
          marginTop: 20,
        }}
      >
        {tabs[activeTab].component}
      </div>
    </div>
  );
}

export default WalletTabs;
