import axios from 'axios';
import { Auth } from 'aws-amplify';
import { atom } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

const APIURL = process.env.REACT_APP_API_HOST;

export const authUserState = atom({
  key: 'authUserState', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const userState = atom({
  key: 'userState', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

Auth.configure({
  Auth: {
    production: process.env.REACT_APP_AUTH_PRODUCTION,
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
  authenticationFlowType: process.env.REACT_APP_AUTH_FLOW_TYPE,
});

const auth = {
  async signIn({ email, phoneNumber }: { email?: string; phoneNumber?: string }) {
    const username = email || phoneNumber || '';

    const queryParams = new URLSearchParams();
    if (email) {
      queryParams.append('email', email);
    }
    if (phoneNumber) {
      queryParams.append('phoneNumber', phoneNumber);
    }

    const res = await fetch(`${APIURL}/Auth/InitialAuth?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.statusText !== 'OK') {
      if (res.statusText === 'Not Found' && !username.includes('+')) {
        alert('User not setup. Please check your username and try again or contact support.');
      }
    }

    // we need to keep this Auth.signIn(username) call to keep the data model that amplify provides
    return Auth.signIn(username);
  },
  async signOut() {
    return Auth.signOut();
  },
  async signUp(email: string) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const passwordLength = 12;
    let password = '';
    for (let i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    const username = uuidv4();
    Auth.signUp({
      username: username,
      password,
      attributes: {
        email,
        name: email,
      },
    });
  },
  async authCode(user: any, code: string) {
    return Auth.sendCustomChallengeAnswer(user, code).then((userdata) => {
      return userdata;
    });
  },
  async getAuthUser() {
    return await Auth.currentAuthenticatedUser();
  },
  async GetUser() {
    return axios.get(`${APIURL}/Auth/GetUser`); //TODO - where does this endpoint exist?
  },
  async credentials() {
    return await Auth.currentSession();
  },

  async postFinishAuth(accessToken: string, idToken: string) {
    const data = { jwtToken: idToken };

    return axios.post(`${APIURL}/Auth/FinishCognitoAuth`, data, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
};

export default auth;
