import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import React, { useState } from 'react';
import reportsService from '../Shared/services/ReportService';
import _ from 'lodash';
import { CardAccordion } from '../components/CardAccordion';

interface Props {
  reports: any[];
}

export default function UsageReporting(props: Props) {
  const [, setDownloadFile] = useState<any>();

  function getReport(id: string, type: string, name: string) {
    reportsService.SecureFile(id).then((res) => {
      setDownloadFile(res.data);
      // console.log(res.data);
      let n = _.snakeCase(name);
      if (!n.includes(type)) {
        n = n + '.' + type;
      }
      if (type === 'csv') {
        type = 'text/csv';
      }
      if (type === 'pdf') {
        type = 'application/pdf';
      }

      downloadURI('data:' + type + ';base64,' + res.data, n);
    });
  }

  function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <IonGrid>
      <IonRow>
        <IonCol size='12' style={{ marginTop: 40 }}>
          <h5>Reporting</h5>
        </IonCol>
      </IonRow>
      <IonRow>
        <CardAccordion title='Column Descriptions'>
          <div>
            <IonRow
              style={{
                color: '#1c1c1c',
              }}
            >
              <IonCol size='4'>
                <strong>Column</strong>
              </IonCol>
              <IonCol size='8'>
                <strong>Description</strong>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>id</strong>
              </IonCol>
              <IonCol size='8'>Internal Maxwell ID</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>eeId</strong>
              </IonCol>
              <IonCol size='8'>Employer provided employee ID</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>firstName</strong>
              </IonCol>
              <IonCol size='8'>First name of the employee</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>lastName</strong>
              </IonCol>
              <IonCol size='8'>Last name of the employee</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>emailAddress</strong>
              </IonCol>
              <IonCol size='8'>Email address of the employee</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>taxableCharges</strong>
              </IonCol>
              <IonCol size='8'>Total budget spent minus any non-taxable expenses</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>budgetTopUps</strong>
              </IonCol>
              <IonCol size='8'>LSA budget received from employer</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>spotBonusReceived</strong>
              </IonCol>
              <IonCol size='8'>Budget received via Recognition Wallets</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>peerRegalosReceived</strong>
              </IonCol>
              <IonCol size='8'>Budget received via peers</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>workLocation</strong>
              </IonCol>
              <IonCol size='8'>Work location of the employee</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>department</strong>
              </IonCol>
              <IonCol size='8'>Department where the employee works</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <strong style={{ fontWeight: 500 }}>generalLedger</strong>
              </IonCol>
              <IonCol size='8'>General ledger information related to the employee</IonCol>
            </IonRow>
          </div>
        </CardAccordion>
      </IonRow>
      <IonRow>
        <IonCol>
          <div
            style={{
              marginTop: 10,
              border: '1px solid black',
              borderRadius: 10,
              padding: 20,
            }}
          >
            <IonRow style={{ fontSize: 12, marginTop: 5 }}>
              <IonCol size='8'>REPORT</IonCol>
              <IonCol size='4'>LINK</IonCol>
            </IonRow>

            {props.reports ? (
              _.orderBy(props.reports, 'date', 'desc').map((item: any) => {
                return (
                  <>
                    <IonRow key={item.id} style={{ marginTop: 5 }}>
                      <IonCol size='8'>{item.name}</IonCol>
                      <IonCol size='4'>
                        <div
                          style={{
                            color: 'blue',
                            cursor: 'pointer',
                            fontWeight: 600,
                          }}
                          onClick={() => {
                            console.log(item.link.split('.')[1]);
                            getReport(item.link, item.link.split('.')[1], item.name.split('.')[0]);
                          }}
                        >
                          {item.link.split('.')[1]
                            ? _.toUpper(item.link.split('.')[1])
                            : _.toUpper(item.type)}
                        </div>
                      </IonCol>
                    </IonRow>
                  </>
                );
              })
            ) : (
              <div style={{ textAlign: 'center' }}>
                <IonSpinner name='lines' />
              </div>
            )}

            {/* {rows && rows.length > 0 && <ReportGrid rows={rows} />} */}
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}
