import React, { useEffect } from 'react';
import { allWalletsState, createWalletModalState, selectedWalletState } from './atoms';
import { useRecoilState } from 'recoil';
import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { add, search } from 'ionicons/icons';
import pluralize from 'pluralize';
import useSearch from '../../Shared/hooks/useSearch';
import WalletService from '../../Shared/services/WalletService';
import { formatDistanceToNow } from 'date-fns';

function WalletSidebar() {
  const [selectedWallet, setSelectedWallet] = useRecoilState(selectedWalletState);
  const [, setIsOpen] = useRecoilState(createWalletModalState);
  const [wallets, setWallets] = useRecoilState(allWalletsState);
  const { results, searchValue, setSearchValue } = useSearch({
    dataSet: wallets,
    keys: ['name', 'description', 'balance', 'members.name', 'members.email'],
  });
  const [loadingWallets, setLoadingWallets] = React.useState(false);
  const [initialWallet, setInitialWallet] = React.useState(true);

  useEffect(() => {
    setLoadingWallets(true);
    WalletService.GetWallets()
      .then((budgetRes: any) => {
        setWallets(budgetRes);
        if (initialWallet && selectedWallet == null && budgetRes.length > 0) {
          setSelectedWallet(budgetRes[0]);
          setInitialWallet(false);
        }
      })
      .finally(() => setLoadingWallets(false));
  }, [selectedWallet]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh)',
        width: 280,
        borderRight: '3px solid black',
      }}
    >
      <div
        style={{
          padding: 12,
        }}
      >
        <h6
          style={{
            marginTop: 140,
          }}
        >
          Current Wallets
        </h6>
        <div
          style={{
            position: 'relative',
          }}
        >
          <IonIcon
            color='primary'
            icon={search}
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
            }}
          />
          <input
            style={{
              width: 240,
              backgroundColor: '#FFF',
              border: '1px solid #EBEBEB',
              padding: 6,
              borderRadius: 10,
              marginTop: 5,
              paddingLeft: 32,
              paddingTop: 2,
              paddingBottom: 2,
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value?.toString() || '')}
          />
        </div>
      </div>
      <div
        style={{
          overflowY: 'auto',
        }}
      >
        {loadingWallets && results.length === 0 && (
          <IonSpinner
            style={{
              marginTop: 24,
              marginLeft: 'calc(50% - 14px)',
            }}
          />
        )}
        {results.map((wallet) => (
          <div
            key={wallet.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 10,
              borderBottom: '1px solid lightgrey',
              cursor: 'pointer',
              backgroundColor: selectedWallet?.id == wallet.id ? '#f7f7f7' : 'transparent',
            }}
            onClick={() => {
              setSelectedWallet(null);
              setTimeout(() => {
                setSelectedWallet(wallet);
              }, 10);
            }}
          >
            <div
              style={{
                width: '58%',
              }}
            >
              <h6
                style={{
                  lineHeight: '20px',
                  marginBottom: 6,
                }}
              >
                {wallet.name}
              </h6>
              <p
                style={{
                  color: 'grey',
                  fontSize: 10,
                  marginBottom: 0,
                }}
              >
                {wallet.lastActivity
                  ? `Last activity was ${formatDistanceToNow(new Date(wallet.lastActivity), {
                      addSuffix: true,
                    })}`
                  : 'No activity'}
              </p>
            </div>
            <div
              style={{
                width: '40%',
                textAlign: 'right',
              }}
            >
              <p
                style={{
                  lineHeight: '20px',
                  fontSize: 12,
                  marginBottom: 6,
                }}
              >
                ${(wallet.balance / 100).toFixed(2)} available
              </p>
              <p
                style={{
                  fontSize: 10,
                  color: 'grey',
                  marginBottom: 0,
                }}
              >
                {wallet.managers?.length} {pluralize('member', wallet.managers?.length)}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          marginTop: 12,
          textAlign: 'center',
        }}
      >
        <IonButton
          fill='clear'
          size='small'
          onClick={() => {
            setIsOpen(true);
          }}
          style={{
            color: '#03989E',
            fontWeight: 400,
            textTransform: 'none',
          }}
        >
          <IonIcon
            icon={add}
            slot='start'
            size='small'
            style={{
              color: '#03989E',
              border: '1px solid #03989E',
              borderRadius: 4,
            }}
          />
          Create a Wallet
        </IonButton>
      </div>
    </div>
  );
}

export default WalletSidebar;
