import { atom } from 'recoil';
import { Anniversary, AnniversaryRun } from '../../Shared/services/AnniversaryService';
import { Wallet } from '../Wallets_Page/types';

export const selectedAnniversaryState = atom<Anniversary | null>({
  key: 'selectedAnniversary',
  default: null,
});

export const newAnniversaryState = atom<Anniversary | null>({
  key: 'newAnniversary',
  default: null,
});

export const selectedAnniversaryRunState = atom<AnniversaryRun[]>({
  key: 'selectedAnniversaryRuns',
  default: [],
});

export const createAnniversaryModalState = atom<boolean>({
  key: 'createAnniversaryModal',
  default: false,
});

export const editAnniversaryModalState = atom<boolean>({
  key: 'editAnniversaryModal',
  default: false,
});

export const anniversaryDataState = atom<Anniversary[]>({
  key: 'anniversaryData',
  default: [],
});

export const anniversaryWalletsState = atom<Wallet[]>({
  key: 'anniversaryWallets',
  default: [],
});
