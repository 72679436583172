import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import NavBar from '../Shared/NavBar';

import { useRecoilState } from 'recoil';
import employerService, { employerState } from '../Shared/services/EmployerService';
import MxCurrency from '../Shared/services/Format';
import ImageService from '../Shared/services/ImageService';
import teamService from '../Shared/services/TeamService';
import auth, { userState } from '../Shared/services/auth';
import SideBar from '../Shared/SideBar';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';

function Recognition_Page() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [employer, setEmployer] = useRecoilState<any>(employerState);

  const [list, setList] = useState<any>(null);

  const [loadingTeamSearch] = useState<boolean>(false);

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      setUser(res.data);
      console.log(ImageService.Url(res.data.picture));

      if (employer == null) {
        employerService.GetEmployer().then((emp: any) => {
          setEmployer(emp.data);
        });
      }

      teamService.TeamRegalos().then((budgetRes: any) => {
        console.log('Team Regalos', budgetRes.data);
        setList(budgetRes.data);
      });
    });
  }, []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 60 },
    { field: 'receiverName', headerName: 'Member', flex: 1 },
    { field: 'senderName', headerName: 'From', flex: 1 },
    {
      field: 'date',
      headerName: 'Date',
      flex: 2,
      valueGetter: (x) => {
        return x.value ? moment(x.value).format('lll') : '';
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 100,
      valueGetter: (x) => {
        return MxCurrency(x.value);
      },
    },
  ];

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <div style={{ flex: 1, marginTop: 0 }}>
          <IonContent fullscreen className='main-content'>
            {' '}
            <NavBar user={user} />
            <div className='main-container'>
              <IonGrid className='ion-no-padding'>
                <IonRow>
                  <IonCol size='8' style={{ paddingTop: 18 }}>
                    <h2>Recognition</h2>
                  </IonCol>
                </IonRow>
                {list && list.length != 0 && (
                  <>
                    <IonCol size='12' style={{ paddingTop: 22 }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ padding: 8, marginBottom: -12 }}>
                          {loadingTeamSearch && <IonSpinner name='lines' />}
                        </div>
                      </div>
                    </IonCol>

                    <DataGrid
                      className='rubix'
                      sx={{
                        border: 'solid black 1px',
                        borderRadius: 2,
                        '& .MuiDataGrid-cell': {
                          color: 'black',
                          fontSize: 16,
                          fontWeight: 400,
                        },
                      }}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      rows={list}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                    />
                  </>
                )}
              </IonGrid>
            </div>
          </IonContent>
        </div>
      </div>
    </IonPage>
  );
}

export default Recognition_Page;
