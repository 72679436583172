import React from 'react';
import 'react-data-grid/lib/styles.css';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { IonContent, IonModal } from '@ionic/react';
import _ from 'lodash';

interface Props {
  rows: any[];
  show: boolean;
  dismiss: () => void;
}

export default function ReportGrid(props: Props) {
  const keys = _.keys(props.rows[0]);

  const columns = keys.map((key) => {
    return {
      field: key,
      headerName: key,
      flex: 1,
      resizable: true,
    };
  });

  const rows = _.orderBy(props.rows, 'id');

  function dismissModal() {
    props.dismiss();
  }

  return (
    <IonModal isOpen={props.show} onDidDismiss={() => dismissModal()} className='mx-modal-wide'>
      <IonContent>
        <div style={{ padding: 22, height: 'calc(90vh - 110px)' }}>
          <h1>Report title</h1>
          <div style={{ textAlign: 'right' }}></div>

          <DataGrid
            className='rubix'
            sx={{
              border: 'solid black 1px',
              borderRadius: 2,
              '& .MuiDataGrid-cell': {
                color: 'black',
                fontSize: 16,
                fontWeight: 400,
              },
            }}
            slots={{ toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </div>
      </IonContent>
    </IonModal>
  );
}

export function InvoiceGrid(props: Props) {
  const keys = _.keys(props.rows[0]);

  const columns = keys.map((key) => {
    return {
      field: key,
      headerName: key,
      flex: 1,
      resizable: true,
    };
  });

  const rows = _.orderBy(props.rows, 'id');

  function dismissModal() {
    props.dismiss();
  }

  return (
    <IonModal isOpen={props.show} onDidDismiss={() => dismissModal()} className='mx-modal-wide'>
      <IonContent>
        <div style={{ padding: 22, height: 'calc(90vh - 110px)' }}>
          <h1>Report title</h1>
          <div style={{ textAlign: 'right' }}></div>

          <DataGrid
            className='rubix'
            sx={{
              border: 'solid black 1px',
              borderRadius: 2,
              '& .MuiDataGrid-cell': {
                color: 'black',
                fontSize: 16,
                fontWeight: 400,
              },
            }}
            slots={{ toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </div>
      </IonContent>
    </IonModal>
  );
}
