function MxCurrency(number: number, round?: boolean | false) {
  if (round == true) {
    return (number * 0.01)
      .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      .toString()
      .split('.')[0];
  }
  return (number * 0.01).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).toString();
}

export default MxCurrency;
