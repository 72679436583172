import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

const benefitsService = {
  async yourBenefits() {
    return axios.get(`${APIURL}/Benefits/YourBenefits`);
  },
};

export default benefitsService;
