import axios from 'axios';
import { TeamQuery } from '../../Team/Team_Page/atoms';

const APIURL = process.env.REACT_APP_API_HOST;

export interface TeamCount {
  all: number;
  notOnboarded: number;
  onboarded: number;
  pending: number;
}

const teamService = {
  async yourTeam(params?: string) {
    let url = `${APIURL}/Team/YourTeam`;
    if (params) {
      url += `?${params}`;
    }
    return axios.get(url);
  },
  async teamCount() {
    return axios.get<TeamCount>(`${APIURL}/Team/TeamMembersCount`);
  },
  async member(id: number) {
    return axios.get(`${APIURL}/Team/Member/${id}`);
  },
  async controlGroups() {
    return axios.get(`${APIURL}/Team/ControlGroups`);
  },
  async memberExit(id: number) {
    return axios.get(`${APIURL}/Team/MemberExit/${id}`);
  },
  async addMember(member: any) {
    return axios.post(`${APIURL}/Team/AddMember`, member);
  },
  // ApproveMember
  async approveMember(id: number) {
    return axios.get(`${APIURL}/Team/ApproveMember/${id}`);
  },
  async setControlGroup(id: number, group: string) {
    return axios.post(`${APIURL}/Team/SetControlGroup/`, {
      memberId: id,
      ControlGroupId: group,
    });
  },
  async setManagerByEmail(memberEmail: string, managerEmail: string) {
    return axios.post(`${APIURL}/Team/SetManagerByEmail/`, {
      MemberEmail: memberEmail,
      ManagerEmail: managerEmail,
    });
  },
  async allTeamMembers(params: TeamQuery) {
    return axios.get(`${APIURL}/Team/AllTeamMembers`, {
      params: {
        page: params.page || 0,
        pageSize: params.pageSize || 10,
        filterField: params.filterField || '',
        filterOperator: params.filterOperator || '',
        filterValue: params.filterValue || '',
        sortField: params.sortField || '',
        sortOrder: params.sortOrder || '',
        onboardingStatus: params.onboardingStatus || 'all',
        search: params.search || '',
      },
    });
  },
  async yourTeamGroups(params?: string) {
    let url = `${APIURL}/Team/YourTeamGroups`;
    if (params) {
      url += `?${params}`;
    }
    return axios.get(url);
  },
  async yourTeamManagers(params?: string) {
    let url = `${APIURL}/Team/YourTeamManagers`;
    if (params) {
      url += `?${params}`;
    }
    return axios.get(url);
  },
  // RegalosTags
  async regalosTags() {
    return axios.get(`${APIURL}/Team/RegalosTags`);
  },

  // DropdownOptions
  async dropdownOptions() {
    return axios.get(`${APIURL}/Team/DropdownOptions`);
  },
  // DropdownOptions
  async TeamAnnouncements() {
    return axios.get(`${APIURL}/Team/TeamAnnouncements`);
  },
  async MemberRegalos(id: number, params?: string) {
    let url = `${APIURL}/Team/MemberRegalos/` + id;
    if (params) {
      url += `?${params}`;
    }

    return axios.get(url);
  },
  async TeamRegalos(params?: string) {
    let url = `${APIURL}/Team/GetTeamRegalos/`;
    if (params) {
      url += `?${params}`;
    }

    return axios.get(url);
  },
  //bulkEditMembers
  async bulkEditMembers(body: any) {
    return axios.post(`${APIURL}/Team/BulkEditMembers`, body);
  },
};

export default teamService;
