import { IonModal, IonButton, IonContent, IonList, IonTextarea, IonIcon } from '@ionic/react';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { allWalletsState, createWalletModalState } from './atoms';
import AccessModal from './AccessModal';
import { closeCircleOutline } from 'ionicons/icons';
import { Member } from '../../Shared/types/member';
import WalletService from '../../Shared/services/WalletService';

function CreateWalletModal() {
  const [isOpen, setIsOpen] = useRecoilState(createWalletModalState);
  const [walletName, setWalletName] = useState('');
  const [funds, setFunds] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [access, setAccess] = useState<Member[]>([]);
  const [description, setDescription] = useState('');
  const [source, setSource] = useState(0);
  const [wallets, setWallets] = useRecoilState(allWalletsState);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    WalletService.CreateWallet({
      name: walletName,
      details: description,
      amount: parseInt(funds),
      managers: access.map((m) => m.id),
      fromWalletId: source,
    })
      .then((res) => {
        setIsOpen(false);
        setWallets((prev) => [...prev, res.data]);
      })
      .finally(() => setLoading(false));
  };

  if (!isOpen) return null;

  return (
    <IonModal className='mx-modal mx-modal-narrow' isOpen={isOpen} backdropDismiss={false}>
      <IonContent className='ion-padding'>
        <div
          style={{
            display: 'flex',
            fontSize: 36,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 22,
            paddingRight: 22,
          }}
        >
          <h6>Welcome to your newest wallet! Let&apos;s set it up.</h6>
          <IonIcon
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setIsOpen(false)}
            icon={closeCircleOutline}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <IonList>
            <b>Add Funds From</b>
            <select
              value={source}
              onChange={(e) => {
                setSource(JSON.parse(e.target.value));
              }}
              style={{
                border: '1px solid black',
                borderRadius: 8,
                padding: 6,
                backgroundColor: '#fcfcfc !important',
                width: '95%',
                boxShadow: '2px 2px 4px -2px rgba(0,0,0,0.75)',
                margin: 10,
              }}
            >
              <option value={0} disabled selected>
                Select budget to use...
              </option>
              {wallets.map((wallet) => (
                <option key={wallet.id} value={wallet.id}>
                  {wallet.name} - ${(wallet.balance / 100).toFixed(2)}{' '}
                  {wallet.isPrimary && '(Central Admin Wallet)'}
                </option>
              ))}
            </select>
            <b>What would you like to name this wallet?</b>
            <input
              className='mx-border'
              value={walletName}
              placeholder='Enter name'
              onChange={(e) => setWalletName(e.target.value?.toString() || '')}
            />
            <b>What amount would you like to add?</b>
            <input
              value={funds}
              className='mx-border'
              type='number'
              placeholder='Enter amount'
              onChange={(e) => setFunds(e.target.value?.toString() || '')}
            />
            <b>Who should have access to this wallet?</b>
            <input
              className='mx-border'
              value={access.map((m) => m.name).join(', ')}
              placeholder='Select members'
              onClick={() => setShowModal(true)}
              tabIndex={-1}
              style={{
                cursor: 'default',
              }}
            />
            <b>Finally, add a description for this wallet&apos;s intended use.</b>
            <IonTextarea
              className='mx-border'
              value={description}
              placeholder='Describe its intended use'
              onIonChange={(e) => setDescription(e.detail.value?.toString() || '')}
              style={{
                margin: 10,
                paddingTop: 0,
              }}
            />
            <IonButton
              color='dark'
              expand='block'
              type='submit'
              style={{
                marginTop: 22,
              }}
              disabled={
                !walletName || !funds || !access.length || !description || !source || loading
              }
            >
              Create This Wallet
            </IonButton>
          </IonList>
        </form>
        <AccessModal
          showModal={showModal}
          selectedUsers={access}
          initialUsers={[]}
          onDismiss={() => setShowModal(false)}
          onSave={(selected) => {
            setAccess(selected);
            setShowModal(false);
          }}
        />
      </IonContent>
    </IonModal>
  );
}

export default CreateWalletModal;
