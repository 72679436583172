import React, { useEffect, useState } from 'react';
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner } from '@ionic/react';
import NavBar from '../Shared/NavBar';

import auth, { userState } from '../Shared/services/auth';
import { useRecoilState } from 'recoil';
import ImageService from '../Shared/services/ImageService';
import BenefitItem from './BenefitItem';
import benefitsService from '../Shared/services/BenefitsService';
import _ from 'lodash';
import employerService from '../Shared/services/EmployerService';
import SideBar from '../Shared/SideBar';

function Benefits_Page() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [, setMarketPlaceCategories] = useState<any>(null);
  const [, setPartners] = useState<any>(null);
  const [benefits, setBenefits] = useState<any>(null);
  const [employer, setEmployer] = useState<any>(null);

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      console.log(ImageService.Url(res.data.picture));
      setUser(res.data);
      employerService.GetEmployer().then((emp: any) => {
        setEmployer(emp.data);
      });
      benefitsService.yourBenefits().then((res: any) => {
        console.log(res.data);
        setBenefits(res.data.yourBenefits);
        setPartners(res.data.yourMarketplace);
        const types = _.keys(_.groupBy(res.data.yourMarketplace, 'typeName'));
        console.log(types);
        setMarketPlaceCategories(types);
      });
    });
  }, []);

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <div style={{ flex: 1, marginTop: 0 }}>
          <IonContent fullscreen className='main-content'>
            <NavBar user={user} />
            <div className='main-container'>
              <IonGrid className='ion-no-padding'>
                <IonRow>
                  <IonCol size='12'>
                    <h2>Benefits+</h2>
                    <h5>Your Life/Work Benefits</h5>
                    <div>
                      Traditional benefits and other resources you offer your team, visible on the
                      Benefits+ tab.
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div
                      style={{
                        marginTop: 40,
                        border: '1px solid black',
                        borderRadius: 10,
                        padding: 20,
                      }}
                    >
                      <IonRow style={{ fontSize: 12 }}>
                        <IonCol size='4'>BENEFIT</IonCol>
                        <IonCol size='6'></IonCol>
                        {/* <IonCol size="2">ENGAGEMENT THIS YEAR</IonCol> */}
                      </IonRow>
                      {benefits ? (
                        benefits.map((item: any) => <BenefitItem key={item.id} item={item} />)
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          <IonSpinner name='lines' />
                        </div>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonContent>
        </div>
      </div>
    </IonPage>
  );
}

export default Benefits_Page;
