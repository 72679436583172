import { atom } from 'recoil';

export const bulkModalAtom = atom({
  key: 'bulkModal',
  default: 'false',
});

export const confirmApproveAtom = atom({
  key: 'confirmApprove',
  default: false,
});

export const selectedRowsAtom = atom({
  key: 'selectedRows',
  default: [] as number[],
});

export interface TeamQuery {
  page?: number;
  pageSize?: number;
  filterField?: string;
  filterOperator?: string;
  filterValue?: string;
  sortField?: string;
  sortOrder?: string;
  onboardingStatus?: string;
  search?: string;
}

export const teamQueryAtom = atom<TeamQuery>({
  key: 'teamQuery',
  default: {
    page: 0,
    pageSize: 10,
    filterField: '',
    filterOperator: '',
    filterValue: '',
    sortField: '',
    sortOrder: '',
    onboardingStatus: 'all',
    search: '',
  },
});

export const teamDataAtom = atom<any | null>({
  key: 'teamData',
  default: null,
});
