import React, { useEffect, useState } from 'react';
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import NavBar from '../Shared/NavBar';
import auth, { userState } from '../Shared/services/auth';
import { useRecoilState } from 'recoil';
import ImageService from '../Shared/services/ImageService';
import employerService, { employerState } from '../Shared/services/EmployerService';
import SideBar from '../Shared/SideBar';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import teamService from '../Shared/services/TeamService';
import moment from 'moment';

function Announcements_Page() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [announcements, setAnnouncements] = useState<any>(null);
  const [employer, setEmployer] = useRecoilState<any>(employerState);

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      console.log(ImageService.Url(res.data.picture));
      setUser(res.data);
      if (employer == null) {
        employerService.GetEmployer().then((emp: any) => {
          setEmployer(emp.data);
        });
      }
      teamService.TeamAnnouncements().then((res: any) => {
        setAnnouncements(res.data);
      });
    });
  }, []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 60 },

    {
      field: 'isActive',
      headerName: 'Active',
      width: 40,
      renderCell: (x) => {
        let active = x.row.isActive;
        if (x.row.whenExpires < new Date() && x.row.whenExpires != null) {
          active = false;
        }
        if (x.row.whenLive > new Date() && x.row.whenLive != null) {
          active = false;
        }
        return active ? '✅' : '';
      },
    },
    {
      field: 'picture',
      headerName: '',
      width: 100,
      renderCell: (x) => {
        return (
          <div
            className='mx-border'
            style={{
              margin: 10,
              backgroundImage: `url(${
                x.row.picture ? x.row.picture : '/assets/member_placeholder.png'
              })`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              height: 40,
              width: 40,
            }}
          ></div>
        );
      },
    },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'body', headerName: 'Body', flex: 2 },
    {
      field: 'whenLive',
      headerName: 'Schedule',
      width: 230,
      renderCell: (x) => {
        return (
          <div>
            {moment(x.row.whenLive).format('LL')}
            <div style={{ fontSize: 12 }}>to: {moment(x.row.whenExpires).format('LL')}</div>
          </div>
        );
      },
    },
  ];

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <div style={{ flex: 1, marginTop: 0 }}>
          <IonContent fullscreen className='main-content'>
            <NavBar user={user} />
            <div className='main-container'>
              <IonGrid className='ion-no-padding'>
                <IonRow>
                  <IonCol size='12'>
                    <h2>Announcements+</h2>
                  </IonCol>
                </IonRow>

                <DataGrid
                  className='rubix'
                  sx={{
                    border: 'solid black 1px',
                    borderRadius: 2,
                    '& .MuiDataGrid-cell': {
                      color: 'black',
                      fontSize: 16,
                      fontWeight: 400,
                    },
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  rows={announcements ? announcements : []}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 20,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                />
              </IonGrid>
            </div>
          </IonContent>
        </div>
      </div>
    </IonPage>
  );
}

export default Announcements_Page;
