import React from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
interface Props {
  item: any;
}

function PartnerItem(props: Props) {
  return (
    <div className='benefit-item'>
      <IonGrid className='ion-no-padding'>
        <IonRow>
          <IonCol size='3' style={{ textAlign: 'center' }}>
            <div
              className='mx-border'
              style={{
                height: 100,
                width: 120,
                backgroundImage: 'url(' + props.item.picture + ')',
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
          </IonCol>
          <IonCol size='9'>
            <h3>{props.item.name}</h3>
            <p>{props.item.description}</p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}

export default PartnerItem;
