import { IonCol, IonRow } from '@ionic/react';
import React from 'react';
import MxCurrency from '../Shared/services/Format';

interface Props {
  fees: any[];
  total: number;
}

export default function AccountFees(props: Props) {
  return (
    <>
      <IonRow style={{ marginTop: 40 }}>
        <IonCol size='12'>
          <h5>Fees</h5>
          <div>
            These are the fees charged on a monthly basis to provide access to Maxwell to your team.
            These are in addition to the budget you provide your team per month.
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <div
            style={{
              marginTop: 10,
              border: '1px solid black',
              borderRadius: 10,
              padding: 20,
            }}
          >
            <IonRow style={{ fontSize: 12 }}>
              <IonCol size='4'>FEE</IonCol>
              <IonCol size='3'>AMOUNT</IonCol>
              <IonCol size='2'>QTY</IonCol>
              <IonCol size='3' style={{ textAlign: 'right' }}>
                TOTAL MONTHLY
              </IonCol>
            </IonRow>

            {props.fees.map((item: any) => (
              <IonRow key={item.name}>
                <IonCol size='4'>{item.name}</IonCol>
                <IonCol size='3'>{MxCurrency(item.amount)}</IonCol>
                <IonCol size='2'>{item.qty}</IonCol>
                <IonCol size='3' style={{ textAlign: 'right' }}>
                  {MxCurrency(item.total)}
                </IonCol>
              </IonRow>
            ))}

            <IonRow>
              <IonCol size='12' style={{ textAlign: 'right', paddingTop: 10 }}>
                Total: <b>{MxCurrency(props.total)}</b>
              </IonCol>
            </IonRow>
          </div>
        </IonCol>
      </IonRow>
    </>
  );
}
