import React, { useState } from 'react';
import { IonModal, IonContent, IonButton, IonIcon } from '@ionic/react';
import WalletService from '../../Shared/services/WalletService';
import { closeCircleOutline } from 'ionicons/icons';
import { useRecoilState } from 'recoil';
import { allWalletsState } from './atoms';

interface BudgetModalProps {
  showModal: boolean;
  onDismiss: () => void;
}

const BANK_ACCOUNT_ID = -1;

const BudgetModal: React.FC<BudgetModalProps> = ({ showModal, onDismiss }) => {
  const [source, setSource] = useState(0);
  const [destination, setDestination] = useState(0);
  const [amount, setAmount] = useState(0);
  const [wallets, setWallets] = useRecoilState(allWalletsState);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setError('');
    if (!source || !destination || !amount) {
      setError('Please fill out all fields');
      return;
    }
    if (
      amount < 0 ||
      amount >
        (wallets.find((w) => w.id == source.toString())?.balance ?? Number.MAX_SAFE_INTEGER) / 100
    ) {
      setError('Invalid amount');
      return;
    }

    setLoading(true);
    WalletService.PostTopUp({
      FromWalletId: source,
      ToWalletId: destination,
      Amount: amount,
    })
      .then(() => WalletService.GetWallets())
      .then((budgetRes: any) => {
        setWallets(budgetRes);
        onDismiss();
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonModal className='mx-modal' isOpen={showModal} onDidDismiss={onDismiss}>
      <IonContent>
        <div
          style={{
            fontSize: 44,
            textAlign: 'right',
            paddingTop: 22,
            paddingRight: 22,
            cursor: 'pointer',
          }}
        >
          <IonIcon onClick={onDismiss} icon={closeCircleOutline} />
        </div>
        <div style={{ padding: 10 }}>
          <b>Send Budget From</b>{' '}
          <div>
            <select
              value={source}
              onChange={(e) => {
                setSource(JSON.parse(e.target.value));
                if (JSON.parse(e.target.value) === BANK_ACCOUNT_ID) {
                  const primaryWallet = wallets.find((w) => w.isPrimary)?.id;
                  setDestination(parseInt(primaryWallet ?? '0'));
                }
              }}
              style={{
                border: '1px solid black',
                borderRadius: 8,
                padding: 6,
                backgroundColor: '#fcfcfc !important',
                width: '100%',
                boxShadow: '2px 2px 4px -2px rgba(0,0,0,0.75)',
              }}
            >
              <option value={0} disabled selected>
                Select budget to use...
              </option>
              <option value={BANK_ACCOUNT_ID}>Bank Account</option>
              {wallets.map((wallet) => (
                <option key={wallet.id} value={wallet.id}>
                  {wallet.name} - ${(wallet.balance / 100).toFixed(2)}{' '}
                  {wallet.isPrimary && '(Central Admin Wallet)'}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ padding: 10 }}>
          <b>Add Budget To</b>{' '}
          <div>
            <select
              disabled={source === BANK_ACCOUNT_ID}
              value={destination}
              onChange={(e) => {
                setDestination(JSON.parse(e.target.value));
              }}
              style={{
                border: source === BANK_ACCOUNT_ID ? '' : '1px solid black',
                borderRadius: 8,
                padding: 6,
                width: '100%',
                boxShadow: '2px 2px 4px -2px rgba(0,0,0,0.75)',
                cursor: source === BANK_ACCOUNT_ID ? 'not-allowed' : 'default',
              }}
            >
              <option value={0} disabled selected>
                Select budget to receive...
              </option>
              {wallets
                .filter((wallet) => wallet.id !== source.toString())
                .map((wallet) => (
                  <option key={wallet.id} value={wallet.id}>
                    {wallet.name} - ${(wallet.balance / 100).toFixed(2)}{' '}
                    {wallet.isPrimary && '(Central Admin Wallet)'}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, padding: 10 }}>
            <b>Top-up Amount</b>
            <div>
              <input
                onChange={(e) => {
                  setAmount(e.target.valueAsNumber);
                }}
                type='number'
                placeholder='$0.00'
                value={amount}
                style={{
                  border: '1px solid black',
                  borderRadius: 8,
                  padding: 5,
                  backgroundColor: '#fcfcfc !important',
                  width: '100%',
                  boxShadow: '2px 2px 3px -2px rgba(0,0,0,0.75)',
                }}
              />
            </div>
          </div>
        </div>
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            marginTop: 10,
          }}
        >
          {error}
        </p>
        <IonButton color='dark' expand='block' onClick={handleSubmit} disabled={loading}>
          Complete
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export default BudgetModal;
