import { atom } from 'recoil';
import { Wallet } from './types';

export const selectedWalletState = atom<Wallet | null>({
  key: 'selectedWallet',
  default: null,
});

export const createWalletModalState = atom<boolean>({
  key: 'createWalletModal',
  default: false,
});

export const allWalletsState = atom<Wallet[]>({
  key: 'allWallets',
  default: [],
});
