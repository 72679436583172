import React, { useState } from 'react';
import { IonButton } from '@ionic/react';
import auth from '../Shared/services/auth';
import ImageService from '../Shared/services/ImageService';
import CONFIG from '../config';

interface Props {
  user: any;
}

function NavBar(props: Props) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <div
          onClick={() => {
            setShowMenu(!showMenu);
          }}
          slot='end'
          style={{ width: 80 }}
        >
          <img
            slot='end'
            className='user-img'
            style={{
              border: '2px solid black',
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.35)',
            }}
            src={
              props.user && props.user.picture
                ? ImageService.Url(props.user.picture)
                : '/assets/icon/icon.png'
            }
            alt='Img'
          />
        </div>
      </div>

      {showMenu && (
        <>
          <div
            onClick={() => {
              setShowMenu(false);
            }}
            style={{
              backgroundColor: 'rgba(0,0,0,0.2)',
              position: 'fixed',
              top: 0,
              right: 0,
              width: '100vw',
              height: '100vw',
              zIndex: 99,
            }}
          ></div>
          <div
            style={{
              width: 260,
              backgroundColor: 'white',
              position: 'fixed',
              color: 'black',
              padding: 10,
              borderRadius: '10px 10px 10px 10px',
              top: 66,
              right: 30,
              border: '2px solid black',
              zIndex: 100,
              textAlign: 'right',
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
            }}
          >
            Maxwell Inc
            <br />
            {props.user && props.user.firstName + ' ' + props.user.lastName}
            <br />
            {props.user && props.user.emailAddress}
            <br />
            <a href='https://my.maxwell.app'>Go to Maxwell App</a>
            <br />
            <br />
            <IonButton
              color='dark'
              onClick={() => {
                auth.signOut();
                localStorage.removeItem('token');
                window.location.href = '/';
              }}
            >
              Logout
            </IonButton>
            <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.5)', marginTop: 6 }}>
              version {CONFIG.APPVERSION}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NavBar;
