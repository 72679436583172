import React, { useEffect } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import NavBar from '../../Shared/NavBar';
import SideBar from '../../Shared/SideBar';
import { useRecoilState } from 'recoil';
import employerService, { employerState } from '../../Shared/services/EmployerService';
import auth, { userState } from '../../Shared/services/auth';
import WalletSidebar from './Sidebar';
import WalletView from './Wallet';
import CreateWalletModal from './CreateWallet';
import { selectedWalletState } from './atoms';

function WalletsPage() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [employer, setEmployer] = useRecoilState<any>(employerState);
  const [selectedWallet] = useRecoilState(selectedWalletState);

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      if (employer == null) {
        employerService.GetEmployer().then((emp: any) => {
          setEmployer(emp.data);
        });
      }
      setUser(res.data);
    });
  }, []);

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <div style={{ flex: 1, marginTop: 0 }}>
          <IonContent fullscreen className='main-content'>
            {' '}
            <NavBar user={user} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100vh',
              }}
            >
              <WalletSidebar />
              <div
                style={{
                  width: '100%',
                  padding: 20,
                  marginTop: 72,
                  overflowY: 'scroll',
                }}
              >
                {selectedWallet && <WalletView />}
              </div>
              <CreateWalletModal />
            </div>
          </IonContent>
        </div>
      </div>
    </IonPage>
  );
}

export default WalletsPage;
