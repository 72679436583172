import React from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import ProtectedRoute from './ProtectedRoute';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-loading-skeleton/dist/skeleton.css';

/* Theme variables */
import './Shared/theme/variables.css';

import Login from './Auth/Login_Page';
import Benefits_Page from './Benefits/Benefits_Page';
import Anniversary_Page from './Team/Anniversary_Page';
import Team_Page from './Team/Team_Page';
import Announcements_Page from './Benefits/Announcements_Page';
import Recognition_Page from './Team/Recognition_Page';
import Wallets_Page from './Team/Wallets_Page';
import Usage_Page from './Settings/Usage_Page';
import Financial_Page from './Settings/Financial_Page';
import Exits_Page from './Settings/Exits_Page';
import Maxwell_Page from './Settings/Maxwell_Page';
import Marketplace_Page from './Benefits/Marketplace_Page';
import Import_Page from './Settings/Import_Page';

let token: string;

axios.interceptors.request.use(async (req) => {
  token = (await Auth.currentSession()).getIdToken().getJwtToken();
  localStorage.setItem('token', token);

  if (req.headers) {
    req.headers['Content-Type'] = 'application/json';
    req.headers['Authorization'] = 'Bearer ' + token;
  }

  return req;
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error === 'No current user') {
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

setupIonicReact({
  swipeBackEnabled: false,
});

const App: React.FC = () => (
  <IonApp>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <IonReactRouter>
      <IonRouterOutlet animated={false}>
        {/**
         * Note - temporarily routing / and /home to Team_Page. Update those to a real home page when we figure out what we want
         * the overview functionality to look like
         */}
        <ProtectedRoute exact path='/'>
          <Team_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/home'>
          <Team_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/anniversaries'>
          <Anniversary_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/benefits'>
          <Benefits_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/marketplace'>
          <Marketplace_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/announcements'>
          <Announcements_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/team'>
          <Team_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/recognition'>
          <Recognition_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/wallets'>
          <Wallets_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/settings'>
          <Usage_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/settings/usage'>
          <Usage_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/settings/financial'>
          <Financial_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/settings/exits'>
          <Exits_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/settings/maxwell'>
          <Maxwell_Page />
        </ProtectedRoute>
        <ProtectedRoute exact path='/import'>
          <Import_Page />
        </ProtectedRoute>
        <Route component={Login} path='/login' />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
