import React, { useEffect } from 'react';
import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';

interface Props {
  banks: any[];
}

export default function BankAccount(props: Props) {
  useEffect(() => {
    console.log(props.banks);
  }, [props.banks]);

  return (
    <>
      <IonRow>
        <IonCol size='12' style={{ marginTop: 40 }}>
          <h5>Your connected bank account</h5>
          <div>
            This is the account where your budget and fees will be drawn from on a monthly basis.
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <div
            style={{
              marginTop: 10,
              border: '1px solid black',
              borderRadius: 10,
              padding: 20,
            }}
          >
            <IonRow style={{ fontSize: 12 }}>
              <IonCol size='4'>INSTITUTION</IonCol>
              <IonCol size='3'>ACCOUNT</IonCol>
              <IonCol size='3'>AGREEMENT</IonCol>
              <IonCol size='2'>VERIFICATION</IonCol>
            </IonRow>

            {props.banks.map((item: any) => (
              <IonRow key={item.name}>
                <IonCol size='4' style={{ paddingTop: 6 }}>
                  {item.name}
                </IonCol>
                <IonCol size='3' style={{ paddingTop: 6 }}>
                  {item.accountType} - {item.accountLastFour}
                </IonCol>
                <IonCol size='3' style={{ paddingTop: 6 }}>
                  {item.agreementLink ? <a href={item.agreementLink}>VIEW</a> : 'PENDING'}
                </IonCol>
                <IonCol size='2' style={{ paddingLeft: 20 }}>
                  {item.verified ? (
                    <IonIcon
                      style={{ fontSize: 34, color: 'green' }}
                      icon={checkmarkCircleOutline}
                    />
                  ) : (
                    <IonIcon style={{ fontSize: 34 }} icon={alertCircleOutline} />
                  )}
                </IonCol>
              </IonRow>
            ))}

            {props.banks.length == 0 && (
              <IonRow>
                <div
                  style={{
                    padding: 10,
                    background: '#eee',
                    borderRadius: 10,
                    fontSize: 14,
                  }}
                >
                  No bank has been connected, contact support@maxwell.app if you would like to
                  enroll in auto-pay
                </div>
              </IonRow>
            )}
          </div>
        </IonCol>
      </IonRow>
    </>
  );
}
