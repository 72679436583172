import React, { useEffect, useState } from 'react';
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner } from '@ionic/react';
import NavBar from '../Shared/NavBar';

import auth, { userState } from '../Shared/services/auth';
import { useRecoilState } from 'recoil';
import Exits from './Exits';
import settingsService from '../Shared/services/SettingsService';
import employerService from '../Shared/services/EmployerService';
import SideBar from '../Shared/SideBar';

function Exits_Page() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [employer, setEmployer] = useState<any>(null);
  const [pageData, setPageData] = useState<any>(null);
  const [segment] = useState<any>('usage');

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      employerService.GetEmployer().then((emp: any) => {
        setEmployer(emp.data);
      });
      console.log('user', res.data);
      setUser(res.data);
    });

    settingsService.account().then((res: any) => {
      console.log(res.data);
      setPageData(res.data);
    });
  }, []);

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <div style={{ flex: 1, marginTop: 0 }}>
          <IonContent fullscreen className='main-content'>
            <NavBar user={user} />
            <div className='main-container'>
              <IonGrid className='ion-no-padding'>
                <IonRow>
                  <IonCol size='4'>
                    <h2>Exits</h2>
                  </IonCol>
                </IonRow>
                {!pageData && (
                  <div style={{ textAlign: 'center' }}>
                    <IonSpinner name='lines' />
                  </div>
                )}
                {pageData && segment == 'usage' && <Exits reports={pageData.exits} />}
              </IonGrid>
            </div>
          </IonContent>
        </div>
      </div>
    </IonPage>
  );
}

export default Exits_Page;
