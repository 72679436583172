import { IonButton, IonCard, IonIcon } from '@ionic/react';
import { informationCircle } from 'ionicons/icons';
import { useState } from 'react';

interface CardAccordionProps {
  title: string;
  children: React.ReactNode;
}

export const CardAccordion = ({ title, children }: CardAccordionProps) => {
  const [cardStatus, setCardStatus] = useState<'closed' | 'hover' | 'open'>('closed');

  return (
    <div>
      <div
        onMouseEnter={() => cardStatus === 'closed' && setCardStatus('hover')}
        onMouseLeave={() => cardStatus === 'hover' && setCardStatus('closed')}
        style={{
          background: 'linear-gradient(to right, rgba(189, 225, 215, 0.1), rgba(5, 154, 160, 0.1))',
          borderRadius: '10px',
          border: '1px solid rgba(1, 83, 87, 0.3)',
          width: '200px',
          height: '36px',
        }}
      >
        <IonButton
          color='light'
          fill='clear'
          size='small'
          onClick={() => setCardStatus(cardStatus === 'open' ? 'closed' : 'open')}
          style={{
            margin: 0,
          }}
        >
          <IonIcon
            slot='start'
            size='small'
            style={{
              color: '#01878C',
            }}
            icon={informationCircle}
          ></IonIcon>
          <p style={{ color: '#01878C', margin: 0 }}>{title}</p>
        </IonButton>
      </div>
      {(cardStatus === 'open' || cardStatus === 'hover') && (
        <IonCard
          style={{
            padding: '12px',
            margin: '12px 0',
            borderRadius: '10px',
            background: '#FFFFFF',
            border: '1px solid rgba(1, 83, 87, 0.3)',
            width: '512px',
          }}
        >
          {children}
        </IonCard>
      )}
    </div>
  );
};
