import React, { useState } from 'react';
import { selectedWalletState } from './atoms';
import { useRecoilState } from 'recoil';
import { IonButton, useIonAlert } from '@ionic/react';
import ImageService from '../../Shared/services/ImageService';
import { Member } from '../../Shared/types/member';
import WalletService from '../../Shared/services/WalletService';
import MemberModal from '../MemberModal';

function WalletMembers() {
  const [selectedWallet, setSelectedWallet] = useRecoilState(selectedWalletState);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [present] = useIonAlert();

  const showDeleteMemberAlert = (member: Member) => {
    present({
      header: 'Confirm Delete',
      message: `Are you sure you want to remove ${member.name} from ${selectedWallet?.name}?`,
      buttons: ['Cancel', 'OK'],
      onDidDismiss: (e) => {
        if (e.detail.role !== 'cancel') {
          WalletService.RemoveMember({
            WalletId: selectedWallet?.id as string,
            MemberId: member.id,
          }).then((res) => {
            setSelectedWallet(res.data);
          });
        }
      },
    });
  };

  if (!selectedWallet || selectedWallet?.managers.length === 0) {
    return (
      <div>
        <p>No members.</p>
      </div>
    );
  }

  return (
    <div>
      {selectedWallet.managers?.map((manager) => (
        <div
          key={manager.id}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <div
            onClick={() => setSelectedMember(manager)}
            className='mx-border'
            style={{
              margin: 10,
              backgroundImage: `url(${
                manager.picture
                  ? ImageService.Url(manager.picture)
                  : '/assets/member_placeholder.png'
              })`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              height: 40,
              width: 40,
              flexShrink: 0,
              cursor: 'pointer',
            }}
          />
          <div
            onClick={() => setSelectedMember(manager)}
            style={{
              flexGrow: 1,
              cursor: 'pointer',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                margin: 0,
              }}
            >
              {manager.name}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: 12,
              }}
            >
              {manager.emailAddress}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 4,
              justifyContent: 'flex-end',
            }}
          >
            <IonButton
              color='light'
              size='small'
              id='present-alert'
              onClick={() => showDeleteMemberAlert(manager)}
            >
              Remove
            </IonButton>
          </div>
        </div>
      ))}
      {selectedMember && (
        <MemberModal
          member={selectedMember}
          showModal
          dismissed={() => setSelectedMember(null)}
          editMember={() => null}
          controlGroups={null}
          employer={null}
          regalosTags={null}
          budgets={null}
          bulkediting={false}
        />
      )}
    </div>
  );
}

export default WalletMembers;
