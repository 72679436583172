import React, { useEffect, useState } from 'react';
import {
  IonModal,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonButton,
  IonIcon,
} from '@ionic/react';
import teamService from '../../Shared/services/TeamService';
import { Member } from '../../Shared/types/member';
import { closeCircleOutline, search } from 'ionicons/icons';

interface AccessModalProps {
  showModal: boolean;
  selectedUsers: Member[];
  initialUsers: Member[];
  onSave: (selected: Member[]) => void;
  onDismiss: () => void;
}

const AccessModal: React.FC<AccessModalProps> = ({
  showModal,
  selectedUsers,
  initialUsers,
  onSave,
  onDismiss,
}) => {
  const [filterText, setFilterText] = useState('');
  const [selected, setSelected] = useState<Member[]>([]);
  const [users, setUsers] = useState<Member[]>([]);

  useEffect(() => setSelected(selectedUsers), [selectedUsers]);

  useEffect(() => {
    if (!showModal) return;
    teamService.yourTeamManagers().then((res) => {
      setUsers(res.data.filter((user: Member) => !initialUsers.some((iu) => iu.id === user.id)));
    });
  }, [showModal]);

  const handleSave = () => {
    onSave(selected);
  };

  const handleCheckboxChange = (value: Member, isChecked: boolean) => {
    const newSelected = isChecked
      ? [...selected, value]
      : selected.filter((item) => item !== value);
    setSelected(newSelected);
  };

  const filteredUsers = users.filter((user) =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(filterText.toLowerCase()),
  );

  return (
    <IonModal className='mx-modal mx-modal-narrow' isOpen={showModal} onDidDismiss={onDismiss}>
      <IonContent>
        <div
          style={{
            fontSize: 36,
            textAlign: 'right',
            paddingTop: 22,
            paddingRight: 22,
          }}
        >
          <IonIcon
            style={{
              cursor: 'pointer',
            }}
            onClick={onDismiss}
            icon={closeCircleOutline}
          />
        </div>
        <div
          style={{
            position: 'relative',
            margin: '0 5px',
          }}
        >
          <IonIcon
            color='primary'
            icon={search}
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
            }}
          />
          <input
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            placeholder='Search'
            style={{
              width: '100%',
              border: '1px solid #EBEBEB',
              backgroundColor: '#FFF',
              padding: 6,
              borderRadius: 10,
              margin: '5px auto',
              paddingLeft: 32,
              paddingTop: 2,
              paddingBottom: 2,
            }}
          />
        </div>
        <IonList>
          {filteredUsers.map((user, index) => (
            <IonItem key={index}>
              <IonLabel>
                {user.firstName} {user.lastName}
              </IonLabel>
              <IonCheckbox
                checked={!!selected.find((item) => item.id === user.id)}
                onIonChange={(e) => handleCheckboxChange(user, e.detail.checked)}
              />
            </IonItem>
          ))}
        </IonList>
        <IonButton color='dark' expand='block' onClick={handleSave}>
          Done
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export default AccessModal;
