import { ButtonHTMLAttributes } from 'react';

export const NumberBadge = ({ value }: { value: number }) => (
  <div
    style={{
      width: 20,
      height: 20,
      backgroundColor: '#ccc',
      textAlign: 'center',
      borderRadius: '50%',
      fontSize: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 4,
    }}
  >
    {value}
  </div>
);

interface ToolbarButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  icon?: string;
}

export const ToolbarButton = ({ label, icon, children, ...props }: ToolbarButtonProps) => (
  <button
    {...props}
    style={{
      color: 'black',
      backgroundColor: 'transparent',
      fontWeight: 350,
      paddingRight: 8,
      opacity: props.disabled ? 0.5 : 1,
      cursor: props.disabled ? 'not-allowed' : 'pointer',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {children}
    {icon && <img src={icon} style={{ width: 20 }} />}
    &nbsp; {label}
  </button>
);
