import axios from 'axios';

const BASEAPIURL = process.env.REACT_APP_BASE_API_HOST;

interface FeatureFlag {
  id: number;
  name: string;
  show: boolean;
  beta: boolean;
}

//TODO - CLEAN - Replace PWA FeatureFlag stuff with a class like this, this is much better
const FeatureFlags = {
  async featureFlags(): Promise<FeatureFlag[]> {
    return axios.get<FeatureFlag[]>(`${BASEAPIURL}/Util/FeatureFlags`).then((response) => {
      return response.data;
    });
  },
  async featureAvailable(name: string) {
    const ffs = await this.featureFlags();
    return ffs.some((ff) => ff.name == name && ff.show);
  },
};

export default FeatureFlags;
