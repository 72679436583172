import React, { useEffect, useState } from 'react';
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner } from '@ionic/react';
import NavBar from '../Shared/NavBar';

import auth, { userState } from '../Shared/services/auth';
import { useRecoilState } from 'recoil';
import ImageService from '../Shared/services/ImageService';
import PartnerItem from './PartnerItem';
import benefitsService from '../Shared/services/BenefitsService';
import _ from 'lodash';
import employerService from '../Shared/services/EmployerService';
import SideBar from '../Shared/SideBar';

function Marketplace_Page() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [marketPlaceCategories, setMarketPlaceCategories] = useState<any>(null);
  const [partners, setPartners] = useState<any>(null);
  const [, setBenefits] = useState<any>(null);
  const [employer, setEmployer] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      console.log(ImageService.Url(res.data.picture));
      setUser(res.data);
      employerService.GetEmployer().then((emp: any) => {
        setEmployer(emp.data);
      });
      benefitsService.yourBenefits().then((res: any) => {
        console.log(res.data);
        setBenefits(res.data.yourBenefits);
        setPartners(res.data.yourMarketplace);
        const types = _.keys(_.groupBy(res.data.yourMarketplace, 'typeName'));
        console.log(types);
        setMarketPlaceCategories(types);
      });
    });
  }, []);

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <div style={{ flex: 1, marginTop: 0 }}>
          <IonContent fullscreen className='main-content'>
            <NavBar user={user} />
            <div className='main-container'>
              <IonGrid className='ion-no-padding'>
                <IonRow style={{ marginTop: 40 }}>
                  <IonCol size='12'>
                    <h5>Marketplace</h5>
                    <div>
                      Curated life/work solutions available to your team on the Marketplace tab.
                    </div>

                    <select
                      style={{
                        width: 240,
                        border: '1px solid black',
                        borderRadius: 4,
                        padding: 6,
                      }}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSelectedCategory(e.target.value);
                      }}
                    >
                      <option value='0'>All</option>
                      {marketPlaceCategories &&
                        marketPlaceCategories.length > 0 &&
                        marketPlaceCategories.map((item: any) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div
                      style={{
                        marginTop: 20,
                        border: '1px solid black',
                        borderRadius: 10,
                        padding: 20,
                      }}
                    >
                      <IonRow style={{ fontSize: 12 }}>
                        <IonCol size='4'>BENEFIT</IonCol>
                      </IonRow>
                      {partners ? (
                        partners
                          .filter((x: any) => {
                            if (selectedCategory && selectedCategory !== '0') {
                              return x.typeName === selectedCategory;
                            }
                            return true;
                          })
                          .map((item: any) => <PartnerItem key={item.id} item={item} />)
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          <IonSpinner name='lines' />
                        </div>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonContent>
        </div>
      </div>
    </IonPage>
  );
}

export default Marketplace_Page;
