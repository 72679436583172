import { IonCol, IonRow, IonSpinner } from '@ionic/react';
import React from 'react';
import reportsService from '../Shared/services/ReportService';
import _ from 'lodash';

interface Props {
  reports: any[];
}

export default function Invoices(props: Props) {
  function getReport(id: string, type: string, name: string) {
    reportsService.SecureFile(id).then((res) => {
      console.log(res.data);
      let n = _.snakeCase(name);
      if (!n.includes(type)) {
        n = n + '.' + type;
      }
      downloadURI('data:' + type + ';base64,' + res.data, n);
    });
  }

  function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <>
      <IonRow>
        <IonCol size='12' style={{ marginTop: 40 }}>
          <h5>Invoices</h5>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <div
            style={{
              marginTop: 10,
              border: '1px solid black',
              borderRadius: 10,
              padding: 20,
            }}
          >
            <IonRow style={{ fontSize: 12, marginTop: 5 }}>
              <IonCol size='8'>INVOICE</IonCol>
              <IonCol size='4'>LINK</IonCol>
            </IonRow>

            {props.reports ? (
              _.orderBy(props.reports, 'date', 'desc').map((item: any) => {
                return (
                  <>
                    <IonRow key={item.id} style={{ marginTop: 5 }}>
                      <IonCol size='8'>{item.name}</IonCol>
                      <IonCol size='4'>
                        <div
                          style={{
                            color: 'blue',
                            cursor: 'pointer',
                            fontWeight: 600,
                          }}
                          onClick={() => {
                            getReport(item.link, item.link.split('.')[1], item.name);
                          }}
                        >
                          {_.toUpper(item.link.split('.')[1])}
                        </div>
                      </IonCol>
                    </IonRow>
                  </>
                );
              })
            ) : (
              <div style={{ textAlign: 'center' }}>
                <IonSpinner name='lines' />
              </div>
            )}

            {/* {rows && rows.length > 0 && <ReportGrid rows={rows} />} */}
          </div>
        </IonCol>
      </IonRow>
    </>
  );
}
