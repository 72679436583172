import React, { useEffect, useState } from 'react';
import { IonButton, IonContent, IonGrid, IonPage } from '@ionic/react';
import NavBar from '../Shared/NavBar';

import auth, { userState } from '../Shared/services/auth';
import { useRecoilState } from 'recoil';
import settingsService from '../Shared/services/SettingsService';
import employerService from '../Shared/services/EmployerService';
import SideBar from '../Shared/SideBar';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import teamService from '../Shared/services/TeamService';
import moment from 'moment';

function Import_Page() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [employer, setEmployer] = useState<any>(null);

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      employerService.GetEmployer().then((emp: any) => {
        setEmployer(emp.data);
      });
      console.log('user', res.data);
      setUser(res.data);
    });

    settingsService.account().then((res: any) => {
      console.log(res.data);
    });
  }, []);

  async function addMember(data: any) {
    const newMember = {
      FirstName: data.firstName,
      LastName: data.lastName,
      EmailAddress: data.emailAddress,
      AltEmailAddress: data.altEmailAddress,
      Phone: data.telephone,
      JobTitle: data.jobTitle,
      CurrentManagerEmail: data.managerEmail,
      ControlGroupId: data.controlGroup,
      StartDate: moment(data.startDate).format('YYYY-MM-DD'),
      AnniversaryDate: moment(data.anniversaryDate).format('YYYY-MM-DD'),
      EeId: data.eeId,
      CurrentManagerId: data.manager,
    };
    console.log('new member', newMember);
    return await teamService
      .addMember(newMember)
      .then((res: any) => {
        return res.data;
      })
      .catch((err: any) => {
        console.log('error', err);
      });
  }

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <div>
          <SideBar employer={employer} />
        </div>
        <div style={{ flex: 1, marginTop: 0 }}>
          <IonContent fullscreen className='main-content'>
            <NavBar user={user} />
            <div className='main-container'>
              <div className='page-title' style={{ marginTop: 20 }}>
                Import Members
              </div>
              <div style={{ paddingBottom: 10, fontSize: 24 }}>To: {employer?.name}</div>
              <IonGrid className='ion-no-padding'>
                <Importer
                  dataHandler={async (rows) => {
                    // required, may be called several times
                    // receives a list of parsed objects based on defined fields and user column mapping;
                    // (if this callback returns a promise, the widget will wait for it before parsing more data)

                    await rows.map(async (row: any) => {
                      console.log(row);
                      const data = {
                        firstName: row.firstName,
                        lastName: row.lastName,
                        emailAddress: row.email,
                        altEmailAddress: row.altEmail,
                        telephone: row.phone,
                        controlGroup: row.controlGroupId,
                        startDate: row.startDate,
                        currentManagerId: row.managerId,
                        managerEmail: row.managerEmail,
                        eeId: row.EeId,
                        jobTitle: row.jobTitle,
                        isAdmin: row.isAdmin,
                        anniversaryDate: row.anniversaryDate,
                        birthDate: row.birthDate,
                      };
                      await addMember(data).then((res: any) => {
                        console.log('res', res);
                      });
                    });

                    // Set managers
                    await rows
                      .filter((x) => x.ManagerEmail)
                      .map(async (row: any) => {
                        if (row.managerId) {
                          await teamService
                            .setManagerByEmail(row.email, row.managerEmail)
                            .then((res: any) => {
                              console.log('res', res);
                            });
                        }
                      });
                  }}
                  defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                  restartable={false} // optional, lets user choose to upload another file when import is complete
                  onClose={() => {
                    // optional, if this is specified the user will see a "Finish" button after import is done,
                    // which will call this when clicked
                    history.back();
                  }}

                  // CSV options passed directly to PapaParse if specified:
                  // delimiter={...}
                  // newline={...}
                  // quoteChar={...}
                  // escapeChar={...}
                  // comments={...}
                  // skipEmptyLines={...}
                  // delimitersToGuess={...}
                  // chunkSize={...} // defaults to 10000
                  // encoding={...} // defaults to utf-8, see FileReader API
                >
                  <ImporterField name='firstName' label='First Name' />
                  <ImporterField name='lastName' label='Last Name' />
                  <ImporterField name='email' label='Email Address' />
                  <ImporterField name='altEmail' label='Alternate Email Address' optional />
                  <ImporterField name='phone' label='Phone Number' />
                  <ImporterField name='controlGroupId' label='Talent Segment ID' />
                  <ImporterField name='startDate' label='Start Date(mm/dd/yyyy)' />
                  <ImporterField name='birthDate' label='Birthday(mm/dd/yyyy)' />
                  <ImporterField name='managerId' label='Manager ID' optional />
                  <ImporterField name='managerEmail' label='Manager Email' optional />
                  <ImporterField name='EeId' label='Employee ID' optional />
                  <ImporterField name='jobTitle' label='Job Title' optional />
                  <ImporterField name='department' label='Department' optional />
                  <ImporterField name='isAdmin' label='Is Admin' optional />
                  <ImporterField
                    name='anniversaryDate'
                    label='Anniversary Date(mm/dd/yyyy)'
                    optional
                  />
                </Importer>
                <div style={{ marginTop: 20, textAlign: 'right' }}>
                  <IonButton
                    color='dark'
                    size='small'
                    onClick={() => {
                      history.back();
                    }}
                  >
                    cancel
                  </IonButton>
                </div>
              </IonGrid>
            </div>
          </IonContent>
        </div>
      </div>
    </IonPage>
  );
}

export default Import_Page;
