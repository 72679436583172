import {
  IonModal,
  IonButton,
  IonContent,
  IonSpinner,
  IonGrid,
  IonIcon,
  IonRow,
  IonCol,
} from '@ionic/react';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  editAnniversaryModalState,
  selectedAnniversaryState,
  anniversaryDataState,
  anniversaryWalletsState,
} from './atoms';
import { closeCircleOutline } from 'ionicons/icons';
import AnniversaryService from '../../Shared/services/AnniversaryService';
import moment from 'moment';
import UserTargetSelector from '../../components/UserTargetSelector';

function EditAnniversaryModal() {
  const [anniversary, setAnniversary] = useRecoilState(selectedAnniversaryState);
  const [, setAnniversaries] = useRecoilState(anniversaryDataState);
  const [isOpen, setIsOpen] = useRecoilState(editAnniversaryModalState);
  const [, setLoading] = useState(false);
  const [wallets] = useRecoilState(anniversaryWalletsState);

  const handleSave = async () => {
    if (anniversary == null) return;
    setLoading(true);
    await AnniversaryService.UpdateAnniversary(anniversary).then((resp) =>
      setAnniversaries((prev) => {
        return prev.map((p) => (p.id === anniversary.id ? resp.data : p));
      }),
    );
    setIsOpen(false);
    setLoading(false);
  };

  const cancel = () => {
    setLoading(true);
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <IonModal className='mx-modal' isOpen={isOpen} backdropDismiss={true} onDidDismiss={cancel}>
      <IonContent className='ion-padding'>
        <div style={{ padding: 22, marginTop: -40 }}>
          {anniversary ? (
            <>
              <div
                style={{
                  display: 'flex',
                  fontSize: 36,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: 22,
                  paddingRight: 22,
                }}
              >
                <h6>Edit your existing anniversary</h6>
                <IonIcon
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={cancel}
                  icon={closeCircleOutline}
                />
              </div>
              <IonGrid className='ion-no-padding'>
                <IonRow>
                  <IonCol>
                    <div>
                      Schedule
                      <input
                        className='mx-border'
                        readOnly={true}
                        placeholder={anniversary.schedule ?? 'Create Anniversary Schedule'}
                        value={anniversary.schedule ?? '0-days'}
                        onChange={(e) =>
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return { ...a, schedule: e.target.value };
                          })
                        }
                      />
                    </div>
                  </IonCol>
                  <IonCol>
                    <div>
                      Amount
                      <input
                        className='mx-border'
                        type='number'
                        placeholder='$0.00'
                        value={anniversary.amount}
                        onChange={(e) =>
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return { ...a, amount: e.target.valueAsNumber };
                          })
                        }
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div>
                      {/** TODO - Some form of tooltip around Message letting users know how to take advantage of it */}
                      Message
                      <input
                        className='mx-border'
                        type='text'
                        value={anniversary.message ?? undefined}
                        onChange={(e) =>
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return { ...a, message: e.target.value };
                          })
                        }
                      />
                    </div>
                  </IonCol>
                  <div>
                    From Wallet
                    <div>
                      <select
                        className='mx-border'
                        value={anniversary?.fromWalletId || 0}
                        onChange={(e) => {
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return {
                              ...a,
                              fromWalletId: parseInt(e.target.value),
                            };
                          });
                        }}
                        style={{
                          padding: 8,
                          backgroundColor: '#fcfcfc !important',
                          width: '100%',
                          marginTop: 10,
                          boxShadow: '2px 2px 4px -2px rgba(0,0,0,0.75)',
                        }}
                      >
                        <option value={0} disabled>
                          Select wallet...
                        </option>
                        {wallets.map((wallet) => (
                          <option key={wallet.id} value={wallet.id}>
                            {wallet.name} - ${(wallet.balance / 100).toFixed(2)}{' '}
                            {wallet.isPrimary && '(Central Admin Wallet)'}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div>
                      Anniversary Run Start Date
                      <input
                        className='mx-border'
                        type='date'
                        value={moment(anniversary.startDate).format('YYYY-MM-DD')}
                        onChange={(e) =>
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return {
                              ...a,
                              startDate: e.target.valueAsDate ?? new Date(),
                            };
                          })
                        }
                      />
                    </div>
                  </IonCol>
                  <IonCol>
                    <div>
                      Anniversary Run End Date
                      <input
                        className='mx-border'
                        type='date'
                        value={
                          anniversary.endDate
                            ? moment(anniversary.endDate).format('YYYY-MM-DD')
                            : 'Never'
                        }
                        onChange={(e) => {
                          const neverEnds = e.target.value === 'Never';
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return {
                              ...a,
                              endDate: neverEnds ? null : (e.target.valueAsDate ?? new Date()),
                            };
                          });
                        }}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <UserTargetSelector
                    onTargetTypeChange={(e) => {
                      setAnniversary((a) => {
                        if (a === null) return null;
                        return {
                          ...a,
                          targetType: parseInt(e.target.value ?? '0'),
                        };
                      });
                    }}
                    onTargetIdChange={(e) => {
                      setAnniversary((a) => {
                        if (a === null) return null;
                        return { ...a, targetIds: e.target.value.toString() };
                      });
                    }}
                  />
                </IonRow>
                <IonRow>
                  <IonButton color='dark' onClick={handleSave}>
                    Update Anniversary
                  </IonButton>
                </IonRow>
                <IonRow>
                  <IonButton color='light' onClick={cancel}>
                    Cancel
                  </IonButton>
                </IonRow>
              </IonGrid>
            </>
          ) : (
            <div style={{ textAlign: 'center', paddingTop: 22 }}>
              <IonSpinner name='lines' />
            </div>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
}

export default EditAnniversaryModal;
