import { useEffect } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import NavBar from '../../Shared/NavBar';
import SideBar from '../../Shared/SideBar';

import { useRecoilState } from 'recoil';
import employerService, { employerState } from '../../Shared/services/EmployerService';
import auth, { userState } from '../../Shared/services/auth';
import AnniversarySidebar from './sidebar';
import '../style.css';
import { anniversaryWalletsState, selectedAnniversaryState } from './atoms';
import AnniversaryView from './AnniversaryView';
import CreateAnniversaryModal from './CreateAnniversaryModal';
import WalletService from '../../Shared/services/WalletService';

function Anniversary_Page() {
  const [user, setUser] = useRecoilState<any>(userState);
  const [employer, setEmployer] = useRecoilState<any>(employerState);
  const [selectedAnniversary] = useRecoilState(selectedAnniversaryState);
  const [, setWallets] = useRecoilState(anniversaryWalletsState);

  useEffect(() => {
    auth.GetUser().then((res: any) => {
      if (employer == null) {
        employerService.GetEmployer().then((emp: any) => {
          setEmployer(emp.data);
        });
      }
      setUser(res.data);
    });

    //set wallets for create/edit anniversary dialogs
    WalletService.GetWallets().then((budgetRes: any) => setWallets(budgetRes));
  }, []);

  return (
    <IonPage>
      <div style={{ display: 'flex' }}>
        <SideBar employer={employer} />
        <div style={{ flex: 1, marginTop: 0 }}>
          <IonContent fullscreen className='main-content'>
            <NavBar user={user} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100vh',
              }}
            >
              <AnniversarySidebar />
              <div
                style={{
                  width: '100%',
                  padding: 20,
                  marginTop: 72,
                  overflowY: 'scroll',
                }}
              >
                {selectedAnniversary && <AnniversaryView />}
              </div>
              <CreateAnniversaryModal />
            </div>
          </IonContent>
        </div>
      </div>
    </IonPage>
  );
}
export default Anniversary_Page;
