import React from 'react';

import Skeleton from 'react-loading-skeleton';

const APIURL = 'https://api.maxwell.community/api';

export function MxImg(props: any) {
  let src = props.src;
  if (src && !src.includes('://')) {
    src = ImageService.Url(props.src);
  }
  return (src && <img {...props} src={src} />) || <Skeleton height={'100%'} />;
}

const ImageService = {
  Url(id: string) {
    if (id && id.includes('://')) {
      return id;
    }
    return id ? APIURL + '/Image/File/' + id : '';
  },
  Uploadable(props: any) {
    return (
      <div
        style={{
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
      >
        <input
          type='file'
          accept='image/*'
          style={{
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 100,
            backgroundColor: 'purple',
          }}
          onChange={(e) => {
            props.selectedImage(e);
          }}
        />
        {props.children}
      </div>
    );
  },
};

export default ImageService;
