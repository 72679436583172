import { GridColDef, GRID_STRING_COL_DEF } from '@mui/x-data-grid';
import MxCurrency from '../../Shared/services/Format';

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    width: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: 'picture',
    headerName: '',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: (x) => (
      <div
        className='mx-border'
        style={{
          margin: 10,
          backgroundImage: `url(${
            x.row.picture ? x.row.picture : '/assets/member_placeholder.png'
          })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          height: 40,
          width: 40,
        }}
      ></div>
    ),
  },
  {
    field: 'eeId',
    headerName: 'EEID',
    width: 72,
    sortable: false,
    filterable: true,
    filterOperators: GRID_STRING_COL_DEF.filterOperators?.filter(
      (operator) => operator.value === 'equals',
    ),
  },
  {
    field: 'name',
    headerName: 'Member',
    width: 200,
    sortable: false,
    filterable: true,
    filterOperators: GRID_STRING_COL_DEF.filterOperators?.filter(
      (operator) => operator.value === 'contains',
    ),
    renderCell: (x) => (
      <div>
        {x.row.name}
        <div style={{ fontSize: 12, color: 'grey' }}>{x.row.email}</div>
      </div>
    ),
  },
  {
    field: 'currentManager',
    headerName: 'Manager',
    sortable: false,
    width: 200,
    filterable: true,
  },
  {
    field: 'controlGroupBudget',
    headerName: 'Talent Segment',
    sortable: false,
    width: 200,
    filterOperators: GRID_STRING_COL_DEF.filterOperators?.filter(
      (operator) => operator.value === 'contains',
    ),
    renderCell: (x) => (
      <div>
        {MxCurrency(x.row.controlGroupBudget)}
        <div style={{ fontSize: 12, color: 'grey', cursor: 'pointer' }}>
          {x.row.controlGroupName}
        </div>
      </div>
    ),
  },
  {
    field: 'balance',
    headerName: 'Current Funds',
    sortable: true,
    filterable: false,
    width: 140,
    valueGetter: (x) => MxCurrency(x.value),
  },
  {
    field: 'workLocation',
    headerName: 'Work Location',
    width: 140,
    sortable: false,
    filterable: true,
    filterOperators: GRID_STRING_COL_DEF.filterOperators?.filter(
      (operator) => operator.value === 'contains',
    ),
  },
  {
    field: 'department',
    headerName: 'Department',
    sortable: false,
    filterable: true,
    filterOperators: GRID_STRING_COL_DEF.filterOperators?.filter(
      (operator) => operator.value === 'contains',
    ),
  },
  {
    field: 'jobTitle',
    headerName: 'Job Title',
    sortable: false,
    filterable: true,
    filterOperators: GRID_STRING_COL_DEF.filterOperators?.filter(
      (operator) => operator.value === 'contains',
    ),
  },
  {
    field: 'anniversaryDate',
    headerName: 'Anniversary',
    sortable: false,
  },
];
