import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

const reportsService = {
  async usageReport(month: number, year: number) {
    return axios.get(`${APIURL}/Reports/UsageReport/${month}/${year}`);
  },
  async SecureFile(id: string) {
    return axios.get(`${APIURL}/Reports/SecureFile/${id}`);
  },
};

export default reportsService;
