import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

const settingsService = {
  async account() {
    return axios.get(`${APIURL}/Settings/Account`);
  },
  async BackAccounts() {
    return axios.get(`${APIURL}/Settings/BackAccounts`);
  },
  // PostReport
  async PostReport(file: any) {
    return axios.post(`${APIURL}/Settings/PostReport`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export default settingsService;
