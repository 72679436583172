import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

export interface Anniversary {
  id: number;
  employerId: number;
  targetType: number;
  targetTypeName: string;
  targetIds?: string | null;
  schedule: string | null;
  amount: number;
  message?: string | null;
  fromWalletId: number;
  startDate: Date;
  endDate?: Date | null;
  isActive: boolean;
}

export interface AnniversaryRun {
  id: number;
  anniversaryId: number;
  employerId: number;
  startedDateTime: Date;
  endedDateTime?: Date | null;
  memberIds?: string | null;
  status: number;
  statusName?: string | null;
}

const AnniversaryService = {
  async GetAnniversaries() {
    return axios.get<Anniversary[]>(`${APIURL}/Anniversary/GetAnniversaryList`);
  },
  async GetAnniversary(id: number) {
    return axios.get<Anniversary>(`${APIURL}/Anniversary/GetAnniversary/${id}`);
  },
  async GetNewAnniversary() {
    return axios.get<Anniversary>(`${APIURL}/Anniversary/GetNewAnniversary`);
  },
  async GetAnniversaryRuns(id: number) {
    return axios.get<AnniversaryRun[]>(`${APIURL}/Anniversary/GetAnniversaryRuns/${id}`);
  },
  async AddAnniversary(anniversary: Anniversary) {
    return axios.post(`${APIURL}/Anniversary/AddAnniversary`, anniversary);
  },
  async UpdateAnniversary(anniversary: Anniversary) {
    return axios.put(`${APIURL}/Anniversary/UpdateAnniversary`, anniversary);
  },
  async SetAnniversaryActive(id: number, isActive: boolean) {
    return axios.put(`${APIURL}/Anniversary/Activate?active=${isActive}&anniversaryIds=${[id]}`);
  },
};

export default AnniversaryService;
