import { IonCol, IonRow } from '@ionic/react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import MxCurrency from '../Shared/services/Format';

interface Props {
  reports: any[];
}

export default function Exits(props: Props) {
  const columns: GridColDef[] = [
    { field: 'eeId', headerName: 'EmployeeId', width: 80 },
    { field: 'name', headerName: 'Member', flex: 1 },
    { field: 'email', headerName: 'Email Address', width: 200 },
    {
      field: 'exitedDate',
      headerName: 'Exit Date',
      width: 150,
      valueGetter: (x: any) => {
        return moment(x.value).format('M/D/YYYY');
      },
    },
    {
      field: 'balanceOnExit',
      headerName: 'Budget at Exit',
      width: 150,
      valueGetter: (x) => {
        return MxCurrency(x.value);
      },
    },
    {
      field: 'exitMonthSpend',
      headerName: 'Exited Month Spend',
      width: 150,
      valueGetter: (x) => {
        return MxCurrency(x.value);
      },
    },
  ];

  return (
    <>
      <IonRow>
        <IonCol>
          <div
            style={{
              marginTop: 10,
              borderRadius: 10,
            }}
          >
            {props.reports && (
              <DataGrid
                className='rubix'
                sx={{
                  border: 'solid black 1px',
                  borderRadius: 2,
                  '& .MuiDataGrid-cell': {
                    color: 'black',
                    fontSize: 16,
                    fontWeight: 400,
                  },
                }}
                slots={{ toolbar: GridToolbar }}
                rows={props.reports}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            )}
          </div>
        </IonCol>
      </IonRow>
    </>
  );
}
