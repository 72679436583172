import { IonButton } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { JsonView, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import _ from 'lodash';
import settingsService from '../Shared/services/SettingsService';
import employerService from '../Shared/services/EmployerService';
import moment from 'moment';
import ReportGrid, { InvoiceGrid } from './ReportGrid';
import { useHistory } from 'react-router';

interface Props {
  employer: any;
}

export default function MaxwellUtils(props: Props) {
  const [employerId, setEmployerId] = useState(props.employer?.id);
  const [message, setMessage] = useState<any>(null);
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [uploadDate, setUploadDate] = useState<any>(null);
  const [uploadInvoice, setUploadInvoice] = useState<any>(null);
  const [uploadInvoiceDate, setInvoiceUploadDate] = useState<any>(null);
  // usage report
  const [usageReportData, setUsageReportData] = useState<any>(null);
  const [showUsageReportModal, setShowUsageReportModal] = useState<any>(false);
  const [usageReportLoading, setUsageReportLoading] = useState<any>(false);
  // invoice report
  const [invoiceReportData, setInvoiceReportData] = useState<any>(null);
  const [showInvoiceReportModal, setShowInvoiceReportModal] = useState<any>(false);
  const [invoiceReportLoading, setInvoiceReportLoading] = useState<any>(false);

  const [bulkedit, setBulkedit] = useState<any>(localStorage.getItem('bulkedit') == 'true');

  const [employers, setEmployers] = useState<any[]>([]);
  const [usageReportMonth, setUsageReportMonth] = useState<any>(moment().format('M'));
  const [usageReportYear, setUsageReportYear] = useState<any>(moment().format('YYYY'));
  const [startingId, setStartingId] = useState<any>(0);

  const fileInputRef = useRef<any>();
  const invoiceInputRef = useRef<any>();

  const APIURL = process.env.REACT_APP_API_HOST;

  const history = useHistory();

  useEffect(() => {
    employerService.GetEmployers().then((res: any) => {
      console.log(res.data);
      setEmployers(res.data);
    });
  }, []);

  return (
    <div style={{ paddingTop: 30 }}>
      <h2>Maxwell Utils</h2>
      <h5>This page is only available to maxwell employee admins</h5>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <div
            style={{
              marginTop: 30,
              paddingTop: 30,
              border: '1px solid black',
              padding: 20,
              borderRadius: 10,
            }}
          >
            <h4>Member Tools</h4>
            Member Id:
            <input
              style={{ width: 200 }}
              className='mx-border'
              type='number'
              placeholder='member id (this doesnt work)'
            />
          </div>
          <div
            style={{
              marginTop: 30,
              paddingTop: 30,
              border: '1px solid black',
              padding: 20,
              borderRadius: 10,
            }}
          >
            <h4>Employer Tools</h4>
            {/* Upload reports to an employer */}
            <div style={{ paddingTop: 30 }}>
              <div style={{ display: 'flex' }}>
                <div>
                  <div>
                    <input
                      value={uploadDate}
                      disabled={!employerId}
                      onChange={(e) => {
                        setUploadDate(e.target.value);
                      }}
                      type='date'
                      className='mx-border'
                    />
                  </div>
                  <input
                    ref={fileInputRef}
                    disabled={!employerId}
                    onChange={(e) => {
                      console.log('file changed');
                      setUploadFile(e);
                    }}
                    type='file'
                    className='mx-border'
                  />
                </div>
                <div style={{ paddingTop: 8 }}>
                  <IonButton
                    disabled={!employerId || !uploadFile || !uploadDate}
                    color='dark'
                    onClick={() => {
                      setMessage({ status: 'working...' });
                      const e = uploadFile;
                      if (e.target.files) {
                        URL.createObjectURL(e.target.files[0]);
                        const formData = new FormData();
                        formData.append('file', e.target.files[0]);
                        formData.append('employerId', (employerId ? employerId : 1).toString());
                        formData.append(
                          'name',
                          e.target.files[0].name.split('.')[0].replace(/_/g, ' '),
                        );
                        formData.append('type', 'report');
                        formData.append('date', uploadDate);

                        settingsService
                          .PostReport(formData)
                          .then((res) => {
                            console.log(res);
                            setMessage(res.data);
                            fileInputRef.current.value = '';
                            setUploadDate('');
                          })
                          .catch((err) => {
                            setMessage(err);
                          });
                      }
                    }}
                  >
                    Upload Report
                  </IonButton>
                </div>
              </div>
              <div>
                This will upload a report and add it to the employers reporting section using the
                name of the file as the report name
              </div>
            </div>
            {/* Upload invoice to an employer */}
            <div style={{ paddingTop: 30 }}>
              <div style={{ display: 'flex' }}>
                <div>
                  <div>
                    <input
                      value={uploadInvoiceDate}
                      disabled={!employerId}
                      onChange={(e) => {
                        setInvoiceUploadDate(e.target.value);
                      }}
                      type='date'
                      className='mx-border'
                    />
                  </div>
                  <input
                    ref={invoiceInputRef}
                    disabled={!employerId}
                    onChange={(e) => {
                      console.log('file changed');
                      setUploadInvoice(e);
                    }}
                    type='file'
                    className='mx-border'
                  />
                </div>
                <div style={{ paddingTop: 8 }}>
                  <IonButton
                    disabled={!employerId || !uploadInvoice || !uploadInvoiceDate}
                    color='dark'
                    onClick={() => {
                      setMessage({ status: 'working...' });
                      const e = uploadInvoice;
                      if (e.target.files) {
                        URL.createObjectURL(e.target.files[0]);
                        const formData = new FormData();
                        formData.append('file', e.target.files[0]);
                        formData.append('employerId', (employerId ? employerId : 1).toString());
                        formData.append(
                          'name',
                          e.target.files[0].name.split('.')[0].replace(/_/g, ' '),
                        );
                        formData.append('type', 'invoice');
                        formData.append('date', uploadInvoiceDate);

                        settingsService
                          .PostReport(formData)
                          .then((res) => {
                            console.log(res);
                            setMessage(res.data);
                            invoiceInputRef.current.value = '';
                            setInvoiceUploadDate('');
                          })
                          .catch((err) => {
                            setMessage(err);
                          });
                      }
                    }}
                  >
                    Upload Invoice
                  </IonButton>
                </div>
              </div>
              <div>
                This will upload a invoice and add it to the employers invoice section using the
                name of the file as the invoice name
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 30,
              paddingTop: 30,
              border: '1px solid black',
              padding: 20,
              borderRadius: 10,
            }}
          >
            <h4>Reporting Tools</h4>

            {/* FixManager */}
            {/* <div style={{ paddingTop: 30 }}>
          <IonButton
            color="dark"
            onClick={() => {
              setMessage({ status: "working..." });
              axios.get(`${APIURL}/Utility/FixManager`).then(
                (res) => {
                  console.log(res.data);
                  setMessage(res.data);
                },
                (err) => {
                  setMessage(err.response.data);
                }
              );
            }}
          >
            FixManager
          </IonButton>
        </div> */}

            {/* FixHireDate */}
            {/* <div style={{ paddingTop: 30 }}>
          <IonButton
            color="dark"
            onClick={() => {
              setMessage({ status: "working..." });
              axios.get(`${APIURL}/Utility/FixHireDate`).then(
                (res) => {
                  console.log(res.data);
                  setMessage(res.data);
                },
                (err) => {
                  setMessage(err.response.data);
                }
              );
            }}
          >
            Fix Hire Date
          </IonButton>
        </div> */}
            {/* FixEnergyLevels */}
            {/* <div style={{ paddingTop: 30 }}>
          <IonButton
            color="dark"
            onClick={() => {
              setMessage({ status: "working..." });
              axios.get(`${APIURL}/Utility/FixEnergyLevels`).then(
                (res) => {
                  console.log(res.data);
                  setMessage(res.data);
                },
                (err) => {
                  setMessage(err.response.data);
                }
              );
            }}
          >
            FixEnergyLevels
          </IonButton>
        </div>  */}

            {/* UsageReport */}
            <div style={{ paddingTop: 0 }}>
              <div style={{ display: 'flex' }}>
                <div>
                  Month
                  <select
                    className='mx-border'
                    value={usageReportMonth}
                    onChange={(e) => setUsageReportMonth(e.target.value)}
                  >
                    {Array.from(Array(12).keys()).map((m) => (
                      <option key={m}>{m + 1}</option>
                    ))}
                  </select>
                </div>
                <div>
                  Year
                  <select
                    className='mx-border'
                    value={usageReportYear}
                    onChange={(e) => setUsageReportYear(e.target.value)}
                  >
                    <option>2022</option>
                    <option>2023</option>
                    <option>2024</option>
                  </select>
                </div>
              </div>
              <div style={{ paddingTop: 30 }}>
                <IonButton
                  color='dark'
                  disabled={!usageReportMonth || !usageReportYear || usageReportLoading}
                  onClick={() => {
                    setUsageReportLoading(true);
                    setMessage({ status: 'working...' });
                    axios
                      .get(
                        `${APIURL}/Reports/UsageReport/` + usageReportMonth + '/' + usageReportYear,
                      )
                      .then(
                        (res) => {
                          console.log(res.data);
                          setMessage('success');
                          setUsageReportData(res.data.rows);
                          setShowUsageReportModal(true);
                          setUsageReportLoading(false);
                        },
                        (err) => {
                          setMessage(err.response.data);
                        },
                      );
                  }}
                >
                  {usageReportLoading ? 'loading...' : 'Usage Report'}
                </IonButton>
                <div>
                  This will generate and display the monthly Usage report for the current SET
                  employer
                </div>
              </div>
              <div style={{ paddingTop: 30 }}>
                <IonButton
                  color='dark'
                  disabled={
                    !usageReportMonth || !usageReportYear || usageReportLoading || !employerId
                  }
                  onClick={() => {
                    setUsageReportLoading(true);
                    setMessage({ status: 'working...' });
                    axios
                      .post(`${APIURL}/Reports/PostUsageReport`, {
                        month: usageReportMonth && parseInt(usageReportMonth),
                        year: usageReportYear,
                        employer_id: employerId && parseInt(employerId),
                      })
                      .then(
                        (res) => {
                          console.log(res.data);
                          setMessage(res.data);
                          setUsageReportLoading(false);
                        },
                        (err) => {
                          setMessage(err.response.data);
                        },
                      );
                  }}
                >
                  Automate Report
                </IonButton>
                <div>
                  This will generate and automatically upload the monthly Usage report for the
                  current SELECTED employer
                </div>
              </div>
              {usageReportData && usageReportData.length > 0 && (
                <ReportGrid
                  rows={usageReportData}
                  show={showUsageReportModal}
                  dismiss={() => setShowUsageReportModal(false)}
                />
              )}
              <div style={{ paddingTop: 30 }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: -8 }}>
                    <div
                      style={{
                        fontSize: 'small',
                        marginBottom: -8,
                        paddingLeft: 12,
                      }}
                    >
                      Invoice #
                    </div>
                    <input
                      placeholder='Starting Invoice #'
                      onChange={(e) => {
                        setStartingId(e.target.value);
                      }}
                      type='number'
                      className='mx-border'
                    />
                  </div>
                  <div style={{ paddingTop: 17 }}>
                    <IonButton
                      color='dark'
                      disabled={
                        !usageReportMonth || !usageReportYear || usageReportLoading || !employerId
                      }
                      onClick={() => {
                        setInvoiceReportLoading(true);
                        setMessage({ status: 'working...' });
                        axios
                          .get(
                            `${APIURL}/Reports/GetEmployerInvoiceReport/` +
                              '' +
                              startingId +
                              `/` +
                              employerId +
                              `/` +
                              usageReportMonth +
                              `/` +
                              usageReportYear,
                          )
                          .then(
                            (res) => {
                              console.log(res.data);
                              setMessage('success');
                              setInvoiceReportData(res.data);
                              setShowInvoiceReportModal(true);
                              setInvoiceReportLoading(false);
                            },
                            (err) => {
                              setMessage(err.response);
                            },
                          );
                      }}
                    >
                      {invoiceReportLoading ? 'loading...' : 'Employer Invoice'}
                    </IonButton>
                    <IonButton
                      color='dark'
                      disabled={!usageReportMonth || !usageReportYear || usageReportLoading}
                      onClick={() => {
                        setInvoiceReportLoading(true);
                        setMessage({ status: 'working...' });
                        axios
                          .get(
                            `${APIURL}/Reports/GetInvoiceReport/` +
                              '' +
                              startingId +
                              `/` +
                              usageReportMonth +
                              `/` +
                              usageReportYear,
                          )
                          .then(
                            (res) => {
                              console.log(res.data);
                              setMessage('success');
                              setInvoiceReportData(res.data);
                              setShowInvoiceReportModal(true);
                              setInvoiceReportLoading(false);
                            },
                            (err) => {
                              setMessage(err.response);
                            },
                          );
                      }}
                    >
                      {invoiceReportLoading ? 'loading...' : 'All Invoices'}
                    </IonButton>
                  </div>
                </div>
                <div>
                  This will generate and display the monthly invoice report for the current SELECTED
                  employer
                </div>
                {invoiceReportData && invoiceReportData.length > 0 && (
                  <InvoiceGrid
                    rows={invoiceReportData}
                    show={showInvoiceReportModal}
                    dismiss={() => setShowInvoiceReportModal(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderRadius: 10,
            paddingLeft: 20,
            paddingTop: 30,
            flex: 1,
          }}
        >
          <div
            style={{
              position: 'sticky',
              top: 70,
              right: 10,
            }}
          >
            <div
              style={{
                border: '1px solid black',
                borderRadius: 10,
                padding: 20,
                marginBottom: 20,
              }}
            >
              <h4>Employer ID</h4>
              <div>Set the employer ID that will be used to run reports on this page</div>
              <div style={{}}>
                <div style={{ flex: 2 }}>
                  <select
                    value={employerId}
                    className='mx-border'
                    onChange={(e: any) => setEmployerId(e.target.value)}
                  >
                    <option value=''>Select Employer</option>
                    {_.orderBy(employers, 'id').map((e) => (
                      <option key={e.id} value={e.id}>
                        {e.id}: {e.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* SetEmployerViewAsId */}
              </div>
            </div>
            <div
              style={{
                border: '1px solid black',
                borderRadius: 10,
                padding: 20,
                marginBottom: 20,
              }}
            >
              <h4>Employer view as ID</h4>
              <div>This will use the above employer ID to set the View As ID for your account</div>
              <div>
                <IonButton
                  disabled={!employerId}
                  color='dark'
                  onClick={() => {
                    setMessage({ status: 'working...' });
                    axios.get(`${APIURL}/Utility/SetEmployerViewAsId/${employerId}`).then(
                      (res) => {
                        console.log(res.data);
                        setMessage(res.data);
                        window.location.reload();
                      },
                      (err) => {
                        setMessage(err.response.data);
                      },
                    );
                  }}
                >
                  Set employer view as ID for HRV
                </IonButton>
                <div>
                  Currently viewing as ID: {props.employer?.id} - {props.employer?.name}
                </div>
              </div>
            </div>
            <div
              style={{
                border: '1px solid black',
                borderRadius: 10,
                padding: 20,
                marginBottom: 20,
              }}
            >
              <h4>Response</h4>
              <JsonView data={message} shouldInitiallyExpand={() => true} style={darkStyles} />
            </div>
            <div
              style={{
                border: '1px solid black',
                borderRadius: 10,
                padding: 20,
              }}
            >
              <IonButton
                color='dark'
                size='small'
                onClick={() => {
                  history.push('/import');
                }}
              >
                Import Members
              </IonButton>
            </div>
            <div
              style={{
                border: '1px solid black',
                borderRadius: 10,
                padding: 20,
                marginTop: 20,
              }}
            >
              {bulkedit ? (
                <IonButton
                  color='dark'
                  size='small'
                  onClick={() => {
                    localStorage.setItem('bulkedit', 'false');
                    setBulkedit(false);
                  }}
                >
                  Disable Bulk edit feature for me
                </IonButton>
              ) : (
                <IonButton
                  color='dark'
                  size='small'
                  onClick={() => {
                    localStorage.setItem('bulkedit', 'true');
                    setBulkedit(true);
                  }}
                >
                  Enable Bulk edit feature for me
                </IonButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
